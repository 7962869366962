import * as React from "react";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import DustReal from "./DustReal";
import DustPrediction from "./DustPrediction";
import DustCar from "./DustCar";
import {  Typography, Box, Tab,Grid } from "@mui/material";

function Dustanalyze() {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        typography: "body1",
      }}
    >
      <Typography
        sx={{
          fontFamily: "paybooc",
          fontSize: 24,
          fontWeight: 700,
          textAlign: "left",
          mt: 5,
          mb: 2,
          ml: 9,
          color: "#3B4056",
        }}
      >
        발생원 분석
      </Typography>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            sx={{ ml: 7 }}
            onChange={handleChange}
            aria-label="lab API tabs example"
            variant="scrollable"
            scrollButtons="auto"
            // centered
          >
            <Tab
              label="실측데이터"
              value="1"
              sx={{
                fontFamily: "Pretendard",
                fontSize: 20,
                fontWeight: 700,
                textAlign: "left",
                color: "#2D3A80",
              }}
            />
            <Tab
              label="예측데이터"
              value="2"
              sx={{
                fontFamily: "Pretendard",
                fontSize: 20,
                fontWeight: 700,
                textAlign: "left",
                color: "#2D3A80",
              }}
            />
            <Tab
              label="분진흡입차 예상운영도"
              sx={{
                fontFamily: "Pretendard",
                fontSize: 20,
                fontWeight: 700,
                textAlign: "left",
                color: "#2D3A80",
              }}
              value="3"
            />
          </TabList>
        </Box>
            <TabPanel value="1">
              <DustReal />
            </TabPanel>
            <TabPanel value="2">
              <DustPrediction />
            </TabPanel>
            <TabPanel value="3">
              <DustCar />
            </TabPanel>
      </TabContext>
    </Box>
  );
}

export default Dustanalyze;
