import { combineReducers } from "redux";
import funGetprofile from "./redux_login";
import funGetDust from "./redux_dust";
import funGetDustPrediction from "./redux_dust_prediction";

import funGetWeather from "./redux_weather";

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["funGetprofile"],
};

const rootReducer = combineReducers({
  funGetprofile,
  funGetDust,
  funGetWeather,
  funGetDustPrediction,
});

export default persistReducer(persistConfig, rootReducer);
