import * as React from "react";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
} from "recharts";

function GraphBar(param) {
  return (
    <ResponsiveContainer id="bar-chart-container" width={220} height={220}>
      <BarChart
        data={param.data}
        margin={{
          top: 5,
          right: 1,
          left: 1,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="Date" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Brush
          dataKey="Date"
          height={15}
          width={150}
          stroke="#8c8686"
          travellerWidth={10}
        />
        <Bar
          dataKey="pm2.5"
          fill="
#B9A292"
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default GraphBar;
