import * as React from "react";
import {
  BarChart,
  Bar,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";




function GraphBrushBarChart(param) {
  return (
    <ResponsiveContainer id="bar-chart-container" width={280} height={220}>
      <BarChart
        data={param.data}
        margin={{
          top: 5,
          right: 10,
          left: 10,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="dongName" />
        <YAxis />
        <Tooltip />
        <Legend verticalAlign="top" wrapperStyle={{ lineHeight: "40px" }} />
        <Brush
          dataKey="dongName"
          height={20}
          stroke="#8884d8"
          travellerWidth={20}
        />
        <Bar dataKey="accValue25" fill="#82ca9d" />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default GraphBrushBarChart;
