import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { Button, Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getProfileParam } from "../modules/redux_login";
import Api from "../Api/Api";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import login_background from "../asset/img/login_background.png";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";

const InputTextField = styled(TextField)({
  "& label": {
    // placeholder text color
    color: "#BCBDC4",
  },
  "& label.Mui-focused": {
    // 해당 input focus 되었을 때 placeholder text color
    // floatng label을 사용할 때 처리 필요하다
    color: "#BCBDC4",
  },
  "& label.Mui-error": {
    color: "#d32f2f",
  },
  "& .MuiOutlinedInput-root": {
    color: "#ffffff",
    "& fieldset": {
      borderColor: "#BCBDC4",
    },
  },
});



function Register() {
  const { getprofile } = useSelector((state) => ({
    profile: state.funGetprofile.profile,
  }));

  const dispatch = useDispatch();
  const ongetProfileParam = (param) => dispatch(getProfileParam(param));
  const [inputId, setInputId] = useState("");
  const [inputPw, setInputPw] = useState("");
  const [inputCheckPw, setInputCheckPw] = useState("");
  const [inputuserAuthority, setUserAuthority] = useState(0);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  // input data 의 변화가 있을 때마다 value 값을 변경해서 useState 해준다
  const handleInputId = (e) => {
    setInputId(e.target.value);
  };

  const handleInputPw = (e) => {
    setInputPw(e.target.value);
  };
  const handleInputCheckPw = (e) => {
    setInputCheckPw(e.target.value);
  };

  const handleuserAuthorityChange = (e) => {
    setUserAuthority(e.target.value);
  };
  const onKeyPress_1 = (e) => {
    e.preventDefault();
    if (e.key === "Enter") {
      onClickRegister();
    }
  };

  // login 버튼 클릭 이벤트
  const onClickRegister = async (e) => {
    e.preventDefault();

    let registerParam = {
      userID: inputId,
      userPW: inputPw,
      userAuthority: inputuserAuthority,
    };
    try {
      const response = await Api.post("DUST/register", registerParam, {
        withCredentials: true,
      });
      if (response.status === 200) {
        // console.log("response.data : ", response.data);
        setInputId("");
        setInputPw("");
        setInputCheckPw("");
        setUserAuthority(0);
        alert("사용자등록 성공");
      }
    } catch (e) {
      alert("사용자등록 실패");
      // console.log(e.response.status);
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        alignContent: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
        backgroundImage: `linear-gradient(90.39deg, #1A2737 0.33%, rgba(26, 39, 55, 0) 99.66%) ,url(${login_background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        marginTop: "-8px",
      }}
    >
      <Grid container justifyContent={"center"} alignItems={"center"}>
        <Box component="form" noValidate autoComplete="off">
          <Typography
            variant="body2"
            sx={{
              fontFamily: "SUIT",
              fontSize: 32,
              fontWeight: 900,
              textAlign: "left",
              color: "#FFFFFF",
              mt: 5,
            }}
          >
            사용자 등록
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontFamily: "SUIT",
              fontSize: 16,
              fontWeight: 500,
              textAlign: "left",
              color: "#FFFFFF",
              mt: 1,
            }}
          >
            등록관리자만 사용자 등록을 할 수 있습니다.
          </Typography>
          <div style={{ marginTop: "50px" }}>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "SUIT",
                fontSize: 18,
                fontWeight: 500,
                textAlign: "left",
                color: "#FFFFFF",
              }}
            >
              아이디
            </Typography>
            <InputTextField
              sx={{ width: 330, height: 54, borderRadius: 2, mt: 0.7 }}
              placeholder="아이디를 입력하세요"
              variant="outlined"
              value={inputId}
              onChange={handleInputId}
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "SUIT",
                fontSize: 18,
                fontWeight: 500,
                textAlign: "left",
                color: "#FFFFFF",
              }}
            >
              비밀번호
            </Typography>
            <InputTextField
              sx={{ width: 330, height: 54, borderRadius: 2, mt: 0.7 }}
              variant="outlined"
              type="password"
              placeholder="비밀번호를 입력하세요"
              value={inputPw}
              onChange={handleInputPw}
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "SUIT",
                fontSize: 18,
                fontWeight: 500,
                textAlign: "left",
                color: "#FFFFFF",
              }}
            >
              비밀번호 확인
            </Typography>
            <InputTextField
              sx={{ width: 330, height: 54, borderRadius: 2, mt: 0.7 }}
              placeholder="비밀번호를 한번 더 입력하세요"
              variant="outlined"
              type="password"
              value={inputCheckPw}
              onChange={handleInputCheckPw}
            />
          </div>
          <div style={{ marginTop: "20px" }}>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "SUIT",
                fontSize: 18,
                fontWeight: 500,
                textAlign: "left",
                color: "#FFFFFF",
              }}
            >
              사용자 권한
            </Typography>
            <Box>
              <FormControl
                sx={{ width: 330, height: 54, borderRadius: 2, mt: 1.7 }}
              >
                <Select
                  value={inputuserAuthority}
                  onChange={handleuserAuthorityChange}
                  sx={{color:"white"}}
                >
                  <MenuItem value={0}>일반사용자</MenuItem>
                  <MenuItem value={1}>일반관리자</MenuItem>
                  <MenuItem value={2}>등록관리자</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>

          <div style={{ marginTop: "60px" }}>
            <Button
              sx={{
                width: 330,
                height: 54,
                borderRadius: 2,
                fontFamily: "SUIT",
                fontSize: 16,
              }}
              variant="contained"
              onClick={onClickRegister}
            >
              사용자등록
            </Button>
          </div>
        </Box>
      </Grid>
    </Box>
  );
}

export default Register;
