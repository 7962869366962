const GET_DUST_PREDICTION = "dust/GET_DUST_MAIN";
const GET_DUST_PREDICTION_PARAM = "dust/GET_DUST_PREDICTION_PARAM";
const GET_DUST_PREDICTION_LIST_PARAM = "dust/GET_DUST_PREDICTION_LIST_PARAM";

export const getDustPrediction = () => ({ type: GET_DUST_PREDICTION });
export const getDustPredictionParam = (param) => ({
  type: GET_DUST_PREDICTION_PARAM,
  param,
});
export const getDustPredictionListParam = (param) => ({
  type: GET_DUST_PREDICTION_LIST_PARAM,
  param,
});

const initialState = {
  dust_prediction: [
    {
      lonlat: [127.3961597522, 36.3379512241],
      dongName: "",
      dong: "",
      pm10Value: "",
      pm25Value: "",
    },
  ],
  dust_prediction_list: [],
};

export default function funGetDustPrediction(state = initialState, action) {
  switch (action.type) {
    case GET_DUST_PREDICTION:
      return {
        ...state,
        dust_prediction: state.dust_prediction,
      };
    case GET_DUST_PREDICTION_PARAM:
      return {
        ...state,
        dust_prediction: action.param,
      };

    case GET_DUST_PREDICTION_LIST_PARAM:
      return {
        ...state,
        dust_prediction_list: action.param,
      };

    default:
      return state;
  }
}
