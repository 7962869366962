import axios from "axios";

const api = axios.create({
  // baseURL: "http://112.217.202.219:8080",
  baseURL: "http://ecobaron.azwellsys.com",

  // baseURL: "http://localhost:5901",
  // baseURL: "http://192.168.99.2:5901",
  //쿼리로 넘길 키들을 prams 객체에 키밸류로 순서대로 넣어준다.
  //   params: {
  //     api_key: "받은 api",
  //     laguage: "밸류",
  //   },
});

export default api;
