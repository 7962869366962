const GET_PROFILE = "login/GET_PROFILE";
const GET_PROFILE_PARAM = "login/GET_PROFILE_PARAM";


export const getProfile = () => ({ type: GET_PROFILE });
export const getProfileParam = (param) => ({ type: GET_PROFILE_PARAM, param });

const initialState = {
  profile: [
    {
      userID: 1234,
      userAuthority: 5678,
      idx:0
    },
  ],
};

export default function funGetprofile(state = initialState, action) {
  switch (action.type) {
    case GET_PROFILE:
      return {
        ...state,
        profile: state.profile,
      };
    case GET_PROFILE_PARAM:
      return {
        ...state,
        profile: action.param,
      };

    default:
      return state;
  }
}
