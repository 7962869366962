import * as React from "react";
import mapboxgl from "mapbox-gl";
import MapboxLanguage from "@mapbox/mapbox-gl-language";
import "mapbox-gl/dist/mapbox-gl.css";
import djdata from "./dj-states.json";

mapboxgl.accessToken =
  "pk.eyJ1IjoicmphdGtkdWRnaCIsImEiOiJjbGU1ZXBxcncwOWxwM29xaW9mYjYxeTczIn0.DECoKxZyn5ye2las_bwxkQ";

function TestJin() {
  const mapContainer = React.useRef();
  // const baseLonlat = [127.55518239819488, 36.29195798555894];
  const baseLonlat = [127.3961597522, 36.3379512241];
  React.useEffect(() => {
    const canvas = document.getElementById("canvasID");
    const ctx = canvas.getContext("2d", { willReadFrequently: true });
    // const drw0 = new TemperatureMap(ctx);
    const bounds = [
      [126.6092734199774, 35.80044806795705],
      [128.15697998365123, 36.85580233610119],
    ];
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      // style: "mapbox://styles/mapbox/outdoors-v11",
      style: "mapbox://styles/mapbox/light-v11",
      // center: [127.40518239819488, 36.332195798555894],
      center: baseLonlat,
      maxBounds: bounds,
      zoom: 7,
      // pitch: 35,
      // bearing: 80,
    });
    // setTestmap(map);
    const language = new MapboxLanguage({ defaultLanguage: "ko" });
    map.addControl(language);
    map.on("load", () => {
      // add mapbox terrain dem source for 3d terrain rendering

      map.addSource("canvas-source", {
        type: "canvas",
        canvas: "canvasID",
        coordinates: [
          // [127.20757545283216, 36.54148449661634],
          // [127.58697998365123, 36.52580233610119],
          // [127.57371261929131, 36.179242005608735],
          // [127.20092734199774, 36.18044806795705],
          map.getBounds().getNorthWest().toArray(),
          map.getBounds().getNorthEast().toArray(),
          map.getBounds().getSouthEast().toArray(),
          map.getBounds().getSouthWest().toArray(),
        ],
        animate: true,
      });
      map.addLayer({
        id: "canvas-layer",
        type: "raster",
        source: "canvas-source",
      });
      map.addSource("states", {
        type: "geojson",
        data: djdata,
      });

      // Add a layer showing the state polygons.
      map.addLayer({
        id: "states-layer",
        type: "fill",
        source: "states",
        paint: {
          "fill-color": "rgba(200, 100, 240, 0)",
          "fill-outline-color": "rgba(200, 100, 240, 1)",
        },
      });

      // When a click event occurs on a feature in the states layer,
      // open a popup at the location of the click, with description
      // HTML from the click event's properties.
    });

    // cleanup function to remove map on unmount

    return () => map.remove();
  }, []);
  return (
    <div>
      <canvas hidden id="canvasID" style={{ overflow: "hidden" }}>
        Canvas not supported
      </canvas>
      <div
        ref={mapContainer}
        style={{
          width: "100wh%",
          height: "100vh",
          position: "relative",
          borderRadius: "10px",
        }}
      >
        <canvas id="cv">Canvas not supported</canvas>
      </div>
    </div>
  );
}

export default TestJin;
