const GET_WEATHER_MAIN = "weather/GET_WEATHER_MAIN";
const GET_WEATHER_MAIN_PARAM = "weather/GET_WEATHER_MAIN_PARAM";

export const getWeatherMain = () => ({ type: GET_WEATHER_MAIN });
export const getWeatherMainParam = (param) => ({
  type: GET_WEATHER_MAIN_PARAM,
  param,
});


const initialState = {
  weather: [
    {
      lonlat: [],
      dongName: "",
      REH: 0,
      T1H: 0,
      RN1: 0,
      UUU: 0,
      VVV: 0,
      PTY: 0,
      VEC: 0,
      WSD: 0,
    },
  ],
  weather_list: [],
};

export default function funGetWeather(state = initialState, action) {
  switch (action.type) {
    case GET_WEATHER_MAIN:
      return {
        ...state,
        weather: state.weather,
      };
    case GET_WEATHER_MAIN_PARAM:
      return {
        ...state,
        weather: action.param,
      };



    default:
      return state;
  }
}
