import React, { useEffect, useState } from "react";
import { Grid, Container, Card, Typography, Box } from "@mui/material";
import TestChart7 from "./TestChart7";
import GraphBar10 from "../Graph/GraphBar10";
import GraphBar25 from "../Graph/GraphBar25";

import GraphAreaSimple10 from "../Graph/GraphAreaSimple10";
import GraphAreaSimple25 from "../Graph/GraphAreaSimple25";

import GraphMixbar10 from "./../Graph/GraphMixbar10";
import GraphMixbar25 from "./../Graph/GraphMixbar25";

import { useSelector, useDispatch } from "react-redux";
import { getDustMainParam } from "../modules/redux_dust";
import Api from "../Api/Api";
import dayjs, { Dayjs } from "dayjs";
import Linear from "../Progress/Linear.jsx";
import year_month_data from "../asset/json/deajeon_5year_month_data.json";
import average_5year from "../asset/json/average_5year.json";
import month_average from "../asset/json/month_average.json";
const styles = {
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  card: {
    position: "relative",
  },
  overlay: {
    position: "absolute",
    top: "20px",
    left: "20px",
    color: "black",
    backgroundColor: "white",
  },
};

function DustTotal() {
  const dispatch = useDispatch();
  const ongetDustMainParam = (param) => dispatch(getDustMainParam(param));
  const [linerProgress, setLinerProgress] = React.useState(false);

  const Dayjs = dayjs();
  const today = new Date();
  const year = today.getFullYear();
  const month = ("0" + (today.getMonth() + 1)).slice(-2);
  const day = ("0" + today.getDate()).slice(-2);
  let time = ("0" + (today.getHours() - 1)).slice(-2);
  const minute = today.getMinutes();
  if (minute <= 40) {
    time = ("0" + (today.getHours() - 2)).slice(-2);
  }
  // console.log("current_time  :", current_time);
  // const max_today = dayjs("20230727");
  const max_today = dayjs(year + month + day);
  const now_dateString = year + month + day + time + "00";

  const getDustRealData = async () => {
    try {
      setLinerProgress(true);
      let dateParam = { date: now_dateString };
      const responseData = await Api.post("DUST/dustRealData", dateParam, {
        withCredentials: true,
      });
      // const dustdata = await responseData.data[0].d_data.replaceAll("\\", "");
      const dustdata = await JSON.parse(responseData.data[0].d_data);
      // console.log(responseData.data);
      ongetDustMainParam(dustdata);
      setLinerProgress(false);
    } catch (e) {
      setLinerProgress(false);
      // alert("통신 요청 실패, 새로고침 해주세요");
      // console.log(e.response.status);
    }
  };
  React.useEffect(() => {
    getDustRealData();
  }, []);
  return (
    <div>
      <Container >
        <Typography
          variant="body2"
          sx={{
            fontFamily: "paybooc",
            fontSize: 24,
            fontWeight: 700,
            textAlign: "left",
            mt: 5,
            mb: 4,
            ml: "auto",
            color: "#3B4056",
          }}
        >
          현황 및 통계
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={2.7}>
            <Grid item xs={12}>
              <Card style={styles.card}>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Pretendard",
                    fontSize: 18,
                    fontWeight: 600,
                    textAlign: "left",
                    mt: 2,
                    mb: 2,
                    ml: 3,
                    color: "#3B4056",
                  }}
                >
                  미세먼지 1년 평균데이터
                </Typography>

                <GraphAreaSimple10 data={average_5year}></GraphAreaSimple10>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card style={styles.card} sx={{ mt: 1 }}>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Pretendard",
                    fontSize: 18,
                    fontWeight: 600,
                    textAlign: "left",
                    mt: 2,
                    mb: 2,
                    ml: 3,
                    color: "#3B4056",
                  }}
                >
                  미세먼지 월별 평균데이터
                </Typography>

                <GraphBar10 data={year_month_data}></GraphBar10>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card style={styles.card} sx={{ mt: 1, mb: 2 }}>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Pretendard",
                    fontSize: 18,
                    fontWeight: 600,
                    textAlign: "left",
                    mt: 2,
                    mb: 2,
                    ml: 3,
                    color: "#3B4056",
                  }}
                >
                  미세먼지 계절별 평균데이터
                </Typography>

                <GraphMixbar10 data={month_average}></GraphMixbar10>
              </Card>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6.6}>
            <Card sx={{ mb: 2 }}>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "Pretendard",
                  fontSize: 18,
                  fontWeight: 600,
                  textAlign: "left",
                  mt: 2,
                  mb: 2,
                  ml: 3,
                }}
              >
                지도데이터
              </Typography>

              <Box sx={{ minHeight: 500, maxHeight: 775, margin: 2 }}>
                <TestChart7 />
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={2.7}>
            <Grid item xs={12}>
              <Card>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Pretendard",
                    fontSize: 18,
                    fontWeight: 600,
                    textAlign: "left",
                    mt: 2,
                    mb: 2,
                    ml: 3,
                    color: "#3B4056",
                  }}
                >
                  초미세먼지 1년 평균데이터
                </Typography>

                <GraphAreaSimple25 data={average_5year}></GraphAreaSimple25>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card sx={{ mt: 1 }}>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Pretendard",
                    fontSize: 18,
                    fontWeight: 600,
                    textAlign: "left",
                    mt: 2,
                    mb: 2,
                    ml: 3,
                    color: "#3B4056",
                  }}
                >
                  초미세먼지 월별 평균데이터
                </Typography>
                <GraphBar25 data={year_month_data}></GraphBar25>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card sx={{ mt: 1, mb: 2 }}>
                <Typography
                  variant="body2"
                  sx={{
                    fontFamily: "Pretendard",
                    fontSize: 18,
                    fontWeight: 600,
                    textAlign: "left",
                    mt: 2,
                    mb: 2,
                    ml: 3,
                    color: "#3B4056",
                  }}
                >
                  초미세먼지 계절별 평균데이터
                </Typography>

                <GraphMixbar25 data={month_average}></GraphMixbar25>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default DustTotal;
