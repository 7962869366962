import * as React from "react";
import { Grid, Container, Card, Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Slider from "@mui/material/Slider";
import Dustdata from "./dust_k_ori.json";
import { useSelector, useDispatch } from "react-redux";
import { getDustMainParam, getDustListParam } from "../modules/redux_dust";
// import { getWeatherMainParam } from "../modules/redux_weather";
import mapboxgl from "mapbox-gl";
import MapboxLanguage from "@mapbox/mapbox-gl-language";
import "mapbox-gl/dist/mapbox-gl.css";
import { TemperatureMap } from "../tempMap/temperatureMap.js";
import djdata from "./dj-states.json";
import Typography from "@mui/material/Typography";
import Api from "../Api/Api";
import dayjs from "dayjs";
import Linear from "../Progress/Linear.jsx";

import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

import colorbarImg from "../asset/img/colorbar3.png";
import markerImg from "../asset/img/location1.png";
import markerImg2 from "../asset/img/location2.png";
import senserDongName from "./senserDongName.json";
import markerTextImg from "../asset/img/markerTextImg.png";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  marginBottom: 8,
}));

mapboxgl.accessToken =
  "pk.eyJ1IjoicmphdGtkdWRnaCIsImEiOiJjbGU1ZXBxcncwOWxwM29xaW9mYjYxeTczIn0.DECoKxZyn5ye2las_bwxkQ";
let DongListTemp = [];
Dustdata.map((item) => DongListTemp.push(item.dongName));
const DongNameList1 = DongListTemp.filter((element, index) => {
  return DongListTemp.indexOf(element) === index;
});
DongNameList1.sort((a, b) => (a < b ? -1 : 1));
const marks = [
  {
    value: 0,
    label: "0:00",
  },
  {
    value: 6,
    label: "6:00",
  },
  {
    value: 12,
    label: "12:00",
  },
  {
    value: 18,
    label: "18:00",
  },
  {
    value: 23,
    label: "23:00",
  },
];

function DustReal() {
  const [linerProgress, setLinerProgress] = React.useState(false);
  const Dayjs = dayjs();
  const today = new Date();
  const year = today.getFullYear();
  const month = ("0" + (today.getMonth() + 1)).slice(-2);
  const day = ("0" + today.getDate()).slice(-2);
  const time = ("0" + (today.getHours() - 1)).slice(-2);
  // console.log("current_time  :", current_time);
  // const max_today = dayjs("20230727");
  const max_today = dayjs(year + month + day);
  const now_dateString = year + month + day + time;
  // const now_dateString = "202307270200";

  const [dateReal, setDateReal] = React.useState(max_today);
  const [datasensorReal, setDatasensorReal] = React.useState();

  const [testmap, setTestmap] = React.useState();
  const [cvTextTime, setCvTextTime] = React.useState(time);
  const [cvTextDate, setCvTextDate] = React.useState("");

  const [autocomplete_value, setAutocomplete_value] = React.useState("");
  const [autocomplete_input_value, setAutocomplete_input_value] =
    React.useState("");

  const [responeDataList, setResponeDataList] = React.useState([]);
  const [responesensorDataList, setResponesensorDataList] = React.useState([]);
  const [responeWeatherData, setResponeWeatherData] = React.useState([]);
  const [weatherData1, setweatherData] = React.useState(autocomplete_value);

  const dispatch = useDispatch();
  const ongetDustMainParam = (param) => dispatch(getDustMainParam(param));
  const ongetDustListParam = (param) => dispatch(getDustListParam(param));
  // const ongetWeatherMainParam = (param) => dispatch(getWeatherMainParam(param));

  const getDustRealData = async (param) => {
    const paramFormat = dayjs(param).format("YYYYMMDD");
    try {
      setLinerProgress(true);
      let dateParam = { date: paramFormat };
      // console.log(dateParam);
      const responseData = await Api.post("DUST/dustRealData", dateParam, {
        withCredentials: true,
      });
      if (responseData.data.length < 1) {
        setLinerProgress(false);
        return alert(
          `${paramFormat} 데이터가 없습니다. 다른 날짜를 선택해 주세요.`
        );
      }
      setResponeDataList(responseData.data);
      ongetDustListParam(responseData.data);
      // const dustdata = await responseData.data[0].d_data.replaceAll("\\", "");
      const dustdata = await JSON.parse(
        responseData.data[responseData.data.length - 1].d_data
      );
      ongetDustMainParam(dustdata);
      setLinerProgress(false);
    } catch (e) {
      // alert("통신 오류 입니다.");
      // console.log(e.response.status);
    }
  };

  const getDustRealsensorData = async (param) => {
    const paramFormat = dayjs(param).format("YYYYMMDD");
    try {
      setLinerProgress(true);
      let timeTemp = ("0" + cvTextTime).slice(-2);
      let dateParam = {
        date: paramFormat,
        time: timeTemp,
      };
      // console.log("dateParam", dateParam);
      const responsesensorData = await Api.post(
        "DUST/dustRealSensorData",
        dateParam,
        {
          withCredentials: true,
        }
      );
      if (responsesensorData.data.length < 1) {
        setLinerProgress(false);
        return alert(
          `${paramFormat} 데이터가 없습니다. 다른 날짜를 선택해 주세요.`
        );
      }
      setResponesensorDataList(responsesensorData.data);
      // const dustdata = await responseData.data[0].d_data.replaceAll("\\", "");
      // console.log("responesensorDataList: ", responesensorDataList);
      setLinerProgress(false);
    } catch (e) {
      // alert("통신 오류 입니다.");
      // console.log(e.response.status);
    }
  };

  const getWeatherRealData = async () => {
    try {
      const responseWeatherData = await Api.get("/DUST/get_weatherdata", {
        withCredentials: true,
      });
      setResponeWeatherData(responseWeatherData.data);
    } catch (e) {
      // alert("날씨 통신 오류 입니다.");
      // console.log(e);
    }
  };

  const getdustList = useSelector((state) => ({
    dust_list: state.funGetDust.dust_list,
  }));

  const getweatherList = useSelector((state) => ({
    weather: state.funGetWeather.weather,
  }));
  const getdustData = useSelector((state) => ({
    dust: state.funGetDust.dust,
  }));
  const mapContainer = React.useRef();
  // const baseLonlat = [127.55518239819488, 36.29195798555894];
  const baseLonlat = [127.3961597522, 36.3379512241];

  let TestDust = getdustData.dust;
  React.useEffect(() => {
    getWeatherRealData();
    // setResponeWeatherData(getweatherList.weather);
  }, []);
  React.useEffect(() => {
    getDustRealData(dateReal);
    setResponeDataList(getdustList.dust_list);
    getDustRealsensorData(dateReal);
    // console.log("respeneDataList :", responeDataList);
  }, [dateReal, responeWeatherData, cvTextTime]);

  // React.useEffect(() => {
  //   console.log("dateReal : ", dateReal);
  // }, [dateReal]);

  React.useEffect(() => {
    // create the map and configure it
    // check out the API reference for more options
    // https://docs.mapbox.com/mapbox-gl-js/api/map/
    if (TestDust.length > 1) {
      const canvas = document.getElementById("canvasID");
      const ctx = canvas.getContext("2d", { willReadFrequently: true });
      const drw0 = new TemperatureMap(ctx);
      const bounds = [
        [126.6092734199774, 35.80044806795705],
        [128.15697998365123, 36.85580233610119],
      ];
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        // style: "mapbox://styles/mapbox/outdoors-v11",
        style: "mapbox://styles/mapbox/light-v11",
        // center: [127.40518239819488, 36.332195798555894],
        center: baseLonlat,
        maxBounds: bounds,
        zoom: 7,
        // pitch: 35,
        // bearing: 80,
      });
      setTestmap(map);
      const language = new MapboxLanguage({ defaultLanguage: "ko" });
      map.addControl(language);

      // Add markers to the map.
      senserDongName.map((item) => {
        // Create a DOM element for each marker.
        const el = document.createElement("div");
        const width1 = 35;
        const height1 = 35;
        el.className = "marker";
        if (
          item.dong === "tli" ||
          item.dong === "neu_2" ||
          item.dong === "jmi" ||
          item.dong === "bsa_b"
        ) {
          el.style.backgroundImage = `url(${markerImg2})`;
        } else {
          el.style.backgroundImage = `url(${markerImg})`;
        }

        el.style.width = `${width1}px`;
        el.style.height = `${height1}px`;
        el.style.backgroundSize = "100%";

        el.addEventListener("click", (e) => {
          let result = responesensorDataList.filter(
            (value) => value.dong === item.dong
          );
          console.log("result :", result);

          //새로운 윈도우 창
          // const HTML = (`${JSON.stringify(result)}`)
          // var newWin = window.open("", "", "height=50,width=1000,left=100,top=500");
          // newWin.document.write(HTML);
        });
        new mapboxgl.Marker(el)
          .setLngLat([item.lonlat[0], item.lonlat[1]])
          .addTo(map);
      });
      let testarray = [];
      let width = map.getCanvas().clientWidth;
      let height = map.getCanvas().clientHeight;
      canvas.width = width;
      canvas.height = height;
      TestDust.map((data1) => {
        const test1 = map.project(data1.lonlat);
        const testObj = {
          x: parseInt(test1.x),
          y: parseInt(test1.y),
          value: data1.pm10Value,
        };
        testarray.push(testObj);
      });

      drw0.setDataPoints(testarray, width, height);

      drw0.drawLow(5, 8, false, function () {});
      //범례 그리기
      const cv = document.getElementById("cv");
      const ctx1 = cv.getContext("2d", { willReadFrequently: true });
      ctx1.canvas.height = 70; //or your desired height
      ctx1.canvas.width = 300; // or your desired width
      ctx1.canvas.style.imageRendering = "auto"; //default
      const image = new Image();
      image.src = `${colorbarImg}`;
      ctx1.clearRect(0, 0, 70, 300);
      if (ctx1 && image) {
        image.onload = function () {
          ctx1.drawImage(image, 0, 0, cv.width, cv.height);
        };
      }
      // drw0.drawFull(true, function () {
      //   // drw0.drawPoints();
      // });

      map.on("load", () => {
        // add mapbox terrain dem source for 3d terrain rendering

        map.addSource("canvas-source", {
          type: "canvas",
          canvas: "canvasID",
          coordinates: [
            // [127.20757545283216, 36.54148449661634],
            // [127.58697998365123, 36.52580233610119],
            // [127.57371261929131, 36.179242005608735],
            // [127.20092734199774, 36.18044806795705],
            map.getBounds().getNorthWest().toArray(),
            map.getBounds().getNorthEast().toArray(),
            map.getBounds().getSouthEast().toArray(),
            map.getBounds().getSouthWest().toArray(),
          ],
          animate: true,
        });
        map.addLayer({
          id: "canvas-layer",
          type: "raster",
          source: "canvas-source",
        });
        map.addSource("states", {
          type: "geojson",
          data: djdata,
        });

        // Add a layer showing the state polygons.
        map.addLayer({
          id: "states-layer",
          type: "fill",
          source: "states",
          paint: {
            "fill-color": "rgba(200, 100, 240, 0)",
            "fill-outline-color": "rgba(200, 100, 240, 1)",
          },
        });

        // When a click event occurs on a feature in the states layer,
        // open a popup at the location of the click, with description
        // HTML from the click event's properties.
        map.on("click", "states-layer", (e) => {
          // console.log(e.lngLat);
          let dongName = e.features[0].properties.EMD_NM;
          let dongInfo = TestDust.filter(
            (data) => data.dongName === e.features[0].properties.EMD_NM
          );
          // console.log("dongInfo", dongInfo[0]);
          const weatherData = responeWeatherData.find(
            (item) => item.dongName === dongName
          );
          onWeatherInfo(weatherData);
          new mapboxgl.Popup()
            // .setLngLat(e.lngLat)
            .setLngLat(dongInfo[0].lonlat)
            // .setLngLat([127.4334984437, 36.4319266863])//평촌동 좌표
            .setHTML(
              `<table> 
                        <tr>
                            <td>${dongName}</td>
                        </tr>
                        <tr>
                            <td>미세먼지</td>
                            <td>:</td>
                            <td>${dongInfo[0].pm10Value.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td>초미세먼지</td>
                            <td>:</td>
                            <td>${dongInfo[0].pm25Value.toFixed(2)}</td>
                        </tr>
              </table>`
            )
            .addTo(map);
        });

        // Change the cursor to a pointer when
        // the mouse is over the states layer.
        map.on("mouseenter", "states-layer", () => {
          map.getCanvas().style.cursor = "pointer";
        });

        // Change the cursor back to a pointer
        // when it leaves the states layer.
        map.on("mouseleave", "states-layer", () => {
          map.getCanvas().style.cursor = "";
        });
      });

      // cleanup function to remove map on unmount

      return () => map.remove();
    }
  }, [TestDust]);

  //켈린더 날짜 선택
  const dateRealValue = (e) => {
    const dateFormat = dayjs(e).format("YYYYMMDD");
    setDateReal(dateFormat);
    setCvTextDate(dateFormat);
    setCvTextTime("");
    // console.log("dateFormat :", dateFormat);
    // setDateReal(e.target.value)
  };

  //slider value
  function valuetext(value) {
    // setCvTextTime(value)
    // console.log("valuetext ",value);
    return `${value}:00`;
  }

  //slider 시간 선택
  function onSliderTimeChange(e) {
    setCvTextDate(dayjs(dateReal).format("YYYYMMDD"));
    setCvTextTime(e.target.value.toString());
    if (e.target.value < 10) {
      const paramTime =
        dayjs(dateReal).format("YYYYMMDD") +
        "0" +
        e.target.value.toString() +
        "00";
      responeDataList.map((item) => {
        if (item.date === paramTime) {
          const dustdata = JSON.parse(item.d_data);
          ongetDustMainParam(dustdata);
          //  ongetDustMainParam(item.data.d_data);
        }
      });
    } else {
      setCvTextDate(dayjs(dateReal).format("YYYYMMDD"));
      const paramTime =
        dayjs(dateReal).format("YYYYMMDD") + e.target.value.toString() + "00";
      responeDataList.map((item) => {
        if (item.date === paramTime) {
          const dustdata = JSON.parse(item.d_data);
          ongetDustMainParam(dustdata);
          //  ongetDustMainParam(item.data.d_data);
        }
      });
    }
  }

  const onWeatherInfo = (info, info2) => {
    let weatherData = null;
    let dustData = null;
    if (typeof info === "string") {
      weatherData = responeWeatherData.find((e) => e.dongName === info);
      dustData = TestDust.find((e) => e.dongName === info);
      if (weatherData == null || weatherData === undefined) {
        return;
      }
      setAutocomplete_value(info);
      setAutocomplete_input_value(info);
      const popup = new mapboxgl.Popup()
        // .setLngLat(e.lngLat)
        // .setLngLat(dongInfo[0].lonlat)
        .setLngLat(dustData.lonlat) //평촌동 좌표
        .setHTML(
          `<table> 
            <tr>
                <td>${weatherData.dongName}</td>
            </tr>
            <tr>
                <td>미세먼지</td>
                <td>:</td>
                <td>${dustData.pm10Value.toFixed(2)}</td>
            </tr>
            <tr>
                <td>초미세먼지</td>
                <td>:</td>
                <td>${dustData.pm25Value.toFixed(2)}</td>
            </tr>
        </table>
        `
        );

      const popups = document.getElementsByClassName("mapboxgl-popup");

      if (popups.length) {
        popups[0].remove();
      }
      popup.addTo(testmap);
    } else if (info == null || info == undefined) {
      return console.log("날씨정보 null");
    } else {
      weatherData = responeWeatherData.find(
        (e) => e.dongName === info.dongName
      );
      dustData = TestDust.find((e) => e.dongName === info.dongName);

      setAutocomplete_value(info.dongName);
      setAutocomplete_input_value(info.dongName);
    }

    let PTY = "정보없음";
    if (weatherData.PTY >= 0 && weatherData.PTY < 0.05) {
      PTY = "맑음";
    } else if (weatherData.PTY >= 0.05 && weatherData.PTY < 0.1) {
      PTY = "약한 비";
    } else if (weatherData.PTY >= 0.1 && weatherData.PTY < 2) {
      PTY = "비";
    } else if (weatherData.PTY >= 2 && weatherData.PTY < 3) {
      PTY = "비+눈";
    } else if (weatherData.PTY >= 3 && weatherData.PTY < 4) {
      PTY = "눈";
    } else if (weatherData.PTY >= 4) {
      PTY = "소나기";
    }
    // weatherData.VEC.toFixed(1);
    let WD = "";
    const vecData = parseInt(weatherData.VEC);
    if (vecData === 90) {
      WD = "동";
    } else if (vecData === 180) {
      WD = "남";
    } else if (vecData === 270) {
      WD = "서";
    } else if (vecData === 0 || vecData === 360) {
      WD = "북";
    } else if (vecData > 90 && vecData < 180) {
      WD = "남동";
    } else if (vecData > 180 && vecData < 270) {
      WD = "남서";
    } else if (vecData > 270 && vecData < 360) {
      WD = "북서";
    } else if (vecData > 0 && vecData < 90) {
      WD = "북동";
    }

    const HTML = (
      <div id="weather_info">
        <Grid container>
          <Grid item sm={6}>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "Pretendard",
                fontSize: 16,
                fontWeight: 600,
                textAlign: "left",
                ml: 3.5,
                mt: 1.7,
                color: "#3B4056",
              }}
            >
              동이름
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "Pretendard",
                fontSize: 16,
                fontWeight: 400,
                textAlign: "right",
                mr: 3.5,
                mt: 1.7,
                color: "#3B4056",
              }}
            >
              {weatherData.dongName}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={6}>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "Pretendard",
                fontSize: 16,
                fontWeight: 600,
                textAlign: "left",
                ml: 3.5,
                mt: 0.7,
                color: "#3B4056",
              }}
            >
              기온
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "Pretendard",
                fontSize: 16,
                fontWeight: 400,
                textAlign: "right",
                mr: 3.5,
                mt: 0.7,
                color: "#3B4056",
              }}
            >
              {weatherData.T1H.toFixed(1)}℃
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={6}>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "Pretendard",
                fontSize: 16,
                fontWeight: 600,
                textAlign: "left",
                ml: 3.5,
                mt: 0.7,
                color: "#3B4056",
              }}
            >
              1시간 강수량
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "Pretendard",
                fontSize: 16,
                fontWeight: 400,
                textAlign: "right",
                mr: 3.5,
                mt: 0.7,
                color: "#3B4056",
              }}
            >
              {parseInt(weatherData.RN1)}mm
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={6}>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "Pretendard",
                fontSize: 16,
                fontWeight: 600,
                textAlign: "left",
                ml: 3.5,
                mt: 0.7,
                color: "#3B4056",
              }}
            >
              습도
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "Pretendard",
                fontSize: 16,
                fontWeight: 400,
                textAlign: "right",
                mr: 3.5,
                mt: 0.7,
                color: "#3B4056",
              }}
            >
              {weatherData.REH.toFixed(1)}%
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={6}>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "Pretendard",
                fontSize: 16,
                fontWeight: 600,
                textAlign: "left",
                ml: 3.5,
                mt: 0.7,
                color: "#3B4056",
              }}
            >
              풍향
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "Pretendard",
                fontSize: 16,
                fontWeight: 400,
                textAlign: "right",
                mr: 3.5,
                mt: 0.7,
                color: "#3B4056",
              }}
            >
              {WD}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={6}>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "Pretendard",
                fontSize: 16,
                fontWeight: 600,
                textAlign: "left",
                ml: 3.5,
                mt: 0.7,
                mb: 2,
                color: "#3B4056",
              }}
            >
              풍속
            </Typography>
          </Grid>
          <Grid item sm={6}>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "Pretendard",
                fontSize: 16,
                fontWeight: 400,
                textAlign: "right",
                mr: 3.5,
                mt: 0.7,
                mb: 2,
                color: "#3B4056",
              }}
            >
              {weatherData.WSD.toFixed(1)}m/s
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
    setLinerProgress(false);
    setweatherData(HTML);
  };
  return (
    <div>
      <Box>
        <Container xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3.5} alignItems="center" justify="center">
              <Grid
                item
                sx={{ marginBottom: 1, marginLeft: 1, marginRight: 1 }}
              >
                <Card>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Pretendard",
                        fontSize: 16,
                        fontWeight: 600,
                        textAlign: "left",
                        mt: 5,
                        mb: 2,
                        ml: 3.5,
                        color: "#303030",
                      }}
                    >
                      실측 날짜
                    </Typography>
                    <DatePicker
                      // maxDate={max_today} //오늘날짜 기준 이상 선택안됨
                      defaultValue={dayjs().add(-2, "hours")}
                      format="YYYY-MM-DD"
                      label="실측 날짜 선택"
                      sx={{ m: 1 }}
                      value={dateReal || ""}
                      showMonthYearPicker
                      onChange={dateRealValue}
                      disableFuture
                    ></DatePicker>
                  </LocalizationProvider>
                  <Box sx={{ m: 5 }}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Pretendard",
                        fontSize: 16,
                        fontWeight: 600,
                        textAlign: "left",
                        mt: 5,
                        mb: 4,
                        ml: -1.5,
                        color: "#303030",
                      }}
                    >
                      시간 선택
                    </Typography>
                    <Slider
                      aria-label="Always visible"
                      defaultValue={0}
                      getAriaValueText={valuetext}
                      step={1}
                      max={responeDataList.length - 1}
                      marks={marks}
                      valueLabelDisplay="on"
                      onChange={onSliderTimeChange}
                    />
                  </Box>
                </Card>
              </Grid>

              <Grid item sx={{ m: 1 }}>
                <Card>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Pretendard",
                      fontSize: 16,
                      fontWeight: 600,
                      textAlign: "left",
                      mt: 5,
                      mb: 3,
                      ml: 3.5,
                      color: "#303030",
                    }}
                  >
                    동 이름 검색
                  </Typography>
                  <Autocomplete
                    disablePortal
                    sx={{ ml: 3, mr: 3, mb: 3 }}
                    id="combo-box-demo"
                    defaultValue={autocomplete_value}
                    options={DongNameList1}
                    value={autocomplete_value || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.dongName === value.dongName
                    }
                    onKeyDown={(e) => {
                      if (e.code === "enter" && e.target.value) {
                        setAutocomplete_value(
                          autocomplete_value.concat(e.target.value)
                        );
                      }
                    }}
                    onChange={(_, newValue) => {
                      if (newValue !== null) {
                        setAutocomplete_value(newValue);
                        onWeatherInfo(newValue);
                      }
                    }}
                    inputValue={autocomplete_value}
                    onInputChange={(_, newInputValue) => {
                      if (newInputValue !== null) {
                        setAutocomplete_value(newInputValue);
                        onWeatherInfo(newInputValue);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="동이름 검색" />
                    )}
                  />
                  <Box>{weatherData1}</Box>
                </Card>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={8.5} alignItems="center" justify="center">
              <Item>
                <Grid container xs={12}>
                  <Grid item sm={2}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Pretendard",
                        fontSize: 18,
                        fontWeight: 600,
                        textAlign: "left",
                        ml: 1.5,
                        color: "#3B4056",
                      }}
                    >
                      실측 날짜
                    </Typography>
                  </Grid>
                  <Grid item sm={5}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Pretendard",
                        fontSize: 18,
                        fontWeight: 400,
                        textAlign: "left",
                        color: "#3B4056",
                      }}
                    >
                      {cvTextDate} {cvTextTime}시 데이터
                    </Typography>
                  </Grid>
                </Grid>
              </Item>
              <Card>
                <Grid container xs={12}>
                  <Card
                    className="m-auto"
                    sx={{ m: 1, minHeight: 600, maxHeight: 671 }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Pretendard",
                        fontSize: 18,
                        fontWeight: 600,
                        textAlign: "left",
                        mt: 3,
                        ml: 3.5,
                        color: "#3B4056",
                      }}
                    >
                      지도 데이터
                    </Typography>
                    <Box sx={{ m: 2 }}>
                      <div id="container_test2">
                        {linerProgress ? <Linear></Linear> : null}
                        <div id="container_test">
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Box
                              sx={{
                                width: "100vw",
                                height: "100vh",
                                justifyContent: "center",
                                justifyItems: "center",
                              }}
                            >
                              <canvas
                                hidden
                                id="canvasID"
                                style={{ overflow: "hidden" }}
                              >
                                Canvas not supported
                              </canvas>
                              <Grid
                                container
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <div
                                  ref={mapContainer}
                                  style={{
                                    width: "760px",
                                    height: "551px",
                                    position: "relative",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <canvas id="cv">Canvas not supported</canvas>
                                </div>
                                <Grid container>
                                  <Grid item sx={{ml:1,mt:1}}>
                                    <img
                                      id="markerTextImg"
                                      width="152px"
                                      height="43px"
                                      src={markerTextImg}
                                      alt="markerTextImg"
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                          </Grid>
                        </div>
                      </div>
                    </Box>
                  </Card>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
}

export default DustReal;
