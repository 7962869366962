import React, { PureComponent } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Label,
  Legend,
} from "recharts";

let dataResult = [];

const COLORS = [ "#2D3A80", "#B8CFEA", "#B38F75"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="middle"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

function GraphPie(dataParam) {

  let data = [];
  let classNames = [];
  if (dataParam.data !== undefined) {
    // console.log("dataParam ", dataParam);
    classNames = dataParam.data[0].classNames;
    // console.log("className ", classNames);
    data = [
      {
        name: "내부",
        value: Number(dataParam.data[0].inner_portion).toFixed(2) * 100,
      },
      {
        name: "외부",
        value: Number(dataParam.data[0].outer_portion).toFixed(2) * 100,
      },
      {
        name: "기상",
        value: Number(dataParam.data[0].weather_portion).toFixed(2) * 100,
      },
    ];
  }


  return (
    <ResponsiveContainer>
      <PieChart  margin={{ bottom: 60,left:10,right:15 }} >
        <Legend layout="vertical" verticalAlign="middle" align="right" />
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          innerRadius={30}
          outerRadius={70}
          fill="#8884d8"
          dataKey="value"
          startAngle={90}
          endAngle={-270}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}

export default GraphPie;
