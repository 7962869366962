import * as React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Brush,
} from "recharts";


function GraphAreaSimple(param) {
  return (
    <ResponsiveContainer id="bar-chart-container" width={220} height={220}>
      <AreaChart
        data={param.data}
        margin={{
          top: 5,
          right: 1,
          left: 1,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="Date" />
        <YAxis />
        <Tooltip />
        <Brush
          dataKey="Date"
          height={15}
          width={150}
          stroke="#8c8686"
          travellerWidth={10}
        />
        <Area type="monotone" dataKey="pm10" stroke="#4571E9" fill="#4571E9" />
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default GraphAreaSimple;
