import * as React from "react";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Box, Grid, Link, Typography, Container } from "@mui/material";
import logo from "../asset/img/inno_logo.png";
import deajeonLogo from "../asset/img/dj_logo.png";
import azwellLogo from "../asset/img/company_logo.png";

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{ background: "#333333", marginTop: "auto", position: "relative" }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={8}>
            <Box
              display="flex"
              justifyContent="flex-start"
              sx={{ marginTop: 3 }}
            >
              <Link href="http://www.azwellsys.com/" color="inherit">
                <img
                  width="146px"
                  height="40px"
                  className="img-responsive"
                  // src="../asset/img/logo1.png"
                  src={azwellLogo}
                  alt="logo"
                />
              </Link>
            </Box>
            <Box display="flex" justifyContent="flex-start">
              <Typography
                variant="body2"
                color="#FFFFFF"
                sx={{
                  marginTop: 2,
                  fontSize: 13,
                  fontFamily: "Pretendard",
                  opacity: 0.8,
                }}
              >
                (주) 에즈웰
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="flex-start"
              sx={{ marginTop: 2 }}
            >
              <Typography
                variant="body2"
                color="#FFFFFF"
                sx={{
                  fontSize: 13,
                  fontWeight: "bold",
                  fontFamily: "Pretendard",
                  opacity: 0.8,
                }}
              >
                주소&nbsp;
              </Typography>
              <Typography
                variant="body2"
                color="#FFFFFF"
                sx={{ fontSize: 13, fontFamily: "Pretendard", opacity: 0.8 }}
              >
                대전광역시 유성구 테크노2로 199 403호(용산동, 미건테크노월드1차)
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="flex-start"
              sx={{ marginTop: 2 }}
            >
              <Typography
                variant="body2"
                color="#FFFFFF"
                sx={{
                  fontSize: 13,
                  fontWeight: "bold",
                  fontFamily: "Pretendard",
                  opacity: 0.8,
                }}
              >
                본사&nbsp;
              </Typography>
              <Typography
                variant="body2"
                color="#FFFFFF"
                sx={{
                  fontSize: 13,
                  fontWeight: "light",
                  fontFamily: "Pretendard",
                  opacity: 0.8,
                }}
              >
                042-824-2907
              </Typography>
              <Typography
                variant="body2"
                color="#FFFFFF"
                sx={{
                  fontSize: 13,
                  fontWeight: "bold",
                  fontFamily: "Pretendard",
                  opacity: 0.8,
                }}
              >
                &nbsp;&nbsp;에즈웰연구소&nbsp;
              </Typography>
              <Typography
                variant="body2"
                color="#FFFFFF"
                sx={{
                  fontSize: 13,
                  fontWeight: "light",
                  fontFamily: "Pretendard",
                  opacity: 0.8,
                }}
              >
                070-7606-2907
              </Typography>
            </Box>
            <Box
              display="flex"
              justifyContent="flex-start"
              sx={{ marginTop: 2 ,ml:-0.8}}
            >
              <Typography
                variant="body2"
                color="#FFFFFF"
                sx={{
                  fontSize: 13,
                  fontWeight: "bold",
                  fontFamily: "Pretendard",
                  opacity: 0.8,
                }}
              >
                &nbsp;&nbsp;FAX&nbsp;
              </Typography>
              <Typography
                variant="body2"
                color="#FFFFFF"
                sx={{
                  fontSize: 13,
                  fontWeight: "light",
                  fontFamily: "Pretendard",
                  opacity: 0.8,
                }}
              >
                042-824-2908
              </Typography>
              <Typography
                variant="body2"
                color="#FFFFFF"
                sx={{
                  fontSize: 13,
                  fontWeight: "bold",
                  fontFamily: "Pretendard",
                  opacity: 0.8,
                }}
              >
                &nbsp;&nbsp;이메일&nbsp;
              </Typography>
              <Typography
                variant="body2"
                color="#FFFFFF"
                sx={{
                  fontSize: 13,
                  fontWeight: "light",
                  fontFamily: "Pretendard",
                  opacity: 0.8,
                }}
              >
                azwell@azwellsys.com
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box display="flex" justifyContent="flex-end">
              <Typography
                variant="body2"
                color="#FFFFFF"
                sx={{
                  marginTop: 4,
                  fontSize: 13,
                  fontWeight: "light",
                  fontFamily: "Pretendard",
                  opacity: 0.8,
                }}
              >
                (주)에즈웰과 함께하는 소중한 파트너 입니다.
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end" mt={2}>
              <Link
                href="https://www.daejeon.go.kr/"
                color="inherit"
                sx={{ pl: 1, pr: 1 }}
              >
                <img
                  width="187px"
                  height="40px"
                  className="img-responsive"
                  // src="../asset/img/logo1.png"
                  src={deajeonLogo}
                  alt="logo"
                />
              </Link>
              <Link
                sx={{ ml: 1 }}
                href="https://www.innopolis.or.kr/inno"
                color="inherit"
              >
                <img
                  width="134px"
                  height="40px"
                  className="img-responsive"
                  // src="../asset/img/logo1.png"
                  src={logo}
                  alt="logo"
                />
              </Link>
            </Box>

            <Box display="flex" justifyContent="flex-end">
              <Typography
                variant="body2"
                color="#ffffff"
                sx={{
                  marginTop: 5,
                  fontSize: 13,
                  fontWeight: "light",
                  fontFamily: "Pretendard",
                  opacity: 0.8,
                }}
              >
                Copyright ⓒ ecobaron.azwellsys.com 2023
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
