import React, { useRef, useEffect, useState, useLayoutEffect } from "react";
import mapboxgl from "mapbox-gl";
import MapboxLanguage from "@mapbox/mapbox-gl-language";
import "mapbox-gl/dist/mapbox-gl.css";
import { TemperatureMap } from "../tempMap/temperatureMap.js";
import djdata from "./dj-states.json";
import { Box, Grid } from "@mui/material";
import markerImg from "../asset/img/markerImg1.png";
import { useSelector, useDispatch } from "react-redux";
import Linear from "../Progress/Linear.jsx";
import colorbarImg from "../asset/img/colorbar3.png";

mapboxgl.accessToken =
  "pk.eyJ1IjoicmphdGtkdWRnaCIsImEiOiJjbGU1ZXBxcncwOWxwM29xaW9mYjYxeTczIn0.DECoKxZyn5ye2las_bwxkQ";

function Test(props) {
  const [linerProgress, setLinerProgress] = React.useState(false);

  const getdustmain = useSelector((state) => ({
    dust: state.funGetDust.dust,
  }));
  const mapContainer = useRef();
  // const baseLonlat = [127.55518239819488, 36.29195798555894];
  const baseLonlat = [127.3961597522, 36.3379512241];
  let TestDust = getdustmain.dust;
  // this is where all of our map logic is going to live
  // adding the empty dependency array ensures that the map
  // is only rendered once

  useEffect(() => {
    // create the map and configure it
    // check out the API reference for more options
    // https://docs.mapbox.com/mapbox-gl-js/api/map/
    setLinerProgress(true);
    if (TestDust.length > 1) {
      const canvas = document.getElementById("canvasID");
      const ctx = canvas.getContext("2d");

      const drw0 = new TemperatureMap(ctx);

      const bounds = [
        [126.6092734199774, 35.80044806795705],
        [128.15697998365123, 36.85580233610119],
      ];
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        // style: "mapbox://styles/mapbox/outdoors-v11",
        style: "mapbox://styles/mapbox/light-v11",
        // center: [127.40518239819488, 36.332195798555894],
        center: props.propsData ? props.propsData : baseLonlat,
        maxBounds: bounds,
        zoom: 7,
        // pitch: 35,
        // bearing: 80,
      });
      const language = new MapboxLanguage({ defaultLanguage: "ko" });
      map.addControl(language);
      // Add markers to the map.

      // Create a DOM element for each marker.
      const el = document.createElement("div");
      const width1 = 30;
      const height1 = 42;
      el.className = "marker";
      el.style.backgroundImage = `url(${markerImg})`;
      el.style.width = `${width1}px`;
      el.style.height = `${height1}px`;
      el.style.backgroundSize = "100%";
      el.addEventListener("click", () => {
        // alert("test message");
      });

      let testarray = [];
      let width = map.getCanvas().clientWidth;
      let height = map.getCanvas().clientHeight;
      canvas.width = width;
      canvas.height = height;
      TestDust.map((data1) => {
        const test1 = map.project(data1.lonlat);

        const testObj = {
          x: parseInt(test1.x),
          y: parseInt(test1.y),
          value: data1.pm10Value,
        };
        testarray.push(testObj);
      });

      drw0.setDataPoints(testarray, width, height);

      drw0.drawLow(5, 8, false, function () {});

      //범례 그리기
      const cv = document.getElementById("cv");
      const ctx1 = cv.getContext("2d", { willReadFrequently: true });
      ctx1.canvas.height = 70; //or your desired height
      ctx1.canvas.width = 300; // or your desired width
      ctx1.canvas.style.imageRendering = "auto"; //default
      const image = new Image();
      image.src = `${colorbarImg}`;
      ctx1.clearRect(0, 0, 100, 100);
      if (ctx1 && image) {
        image.onload = function () {
          ctx1.drawImage(image, 0, 0, cv.width, cv.height);
        };
      }

      map.on("load", () => {
        // add mapbox terrain dem source for 3d terrain rendering
        map.addSource("canvas-source", {
          type: "canvas",
          canvas: "canvasID",
          coordinates: [
            // [127.20757545283216, 36.54148449661634],
            // [127.58697998365123, 36.52580233610119],
            // [127.57371261929131, 36.179242005608735],
            // [127.20092734199774, 36.18044806795705],
            map.getBounds().getNorthWest().toArray(),
            map.getBounds().getNorthEast().toArray(),
            map.getBounds().getSouthEast().toArray(),
            map.getBounds().getSouthWest().toArray(),
          ],
          animate: true,
        });
        map.addLayer({
          id: "canvas-layer",
          type: "raster",
          source: "canvas-source",
        });
        map.addSource("states", {
          type: "geojson",
          data: djdata,
        });

        // Add a layer showing the state polygons.
        map.addLayer({
          id: "states-layer",
          type: "fill",
          source: "states",
          paint: {
            "fill-color": "rgba(200, 100, 240, 0)",
            "fill-outline-color": "rgba(200, 100, 240, 1)",
          },
        });

        // When a click event occurs on a feature in the states layer,
        // open a popup at the location of the click, with description
        // HTML from the click event's properties.
        map.on("click", "states-layer", (e) => {
          // console.log(e.lngLat);
          let dongName = e.features[0].properties.EMD_NM;
          let dongInfo = TestDust.filter(
            (data) => data.dongName === e.features[0].properties.EMD_NM
          );
          // console.log("dongInfo", dongInfo[0]);

          new mapboxgl.Popup()
            // .setLngLat(e.lngLat)
            .setLngLat(dongInfo[0].lonlat)
            // .setLngLat([127.4334984437, 36.4319266863])//평촌동 좌표
            .setHTML(
              `<table >
                        <tr>
                            <td>${dongName}</td>
                        </tr>
                        <tr>
                            <td>PM10</td>
                            <td>:</td>
                            <td>${dongInfo[0].pm10Value.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td>PM25</td>
                            <td>:</td>
                            <td>${dongInfo[0].pm25Value.toFixed(2)}</td>
                        </tr>
              </table>`
            )
            .addTo(map);
        });

        // Change the cursor to a pointer when
        // the mouse is over the states layer.
        map.on("mouseenter", "states-layer", () => {
          map.getCanvas().style.cursor = "pointer";
        });

        // Change the cursor back to a pointer
        // when it leaves the states layer.
        map.on("mouseleave", "states-layer", () => {
          map.getCanvas().style.cursor = "";
        });
      });

      // cleanup function to remove map on unmount
      setLinerProgress(false);

      return () => map.remove();
    }
  }, [TestDust]);

  return (
    <div id="container_test2">
      {linerProgress ? <Linear></Linear> : null}
      <div id="container_test">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Box
              sx={{
                width: "100vw",
                height: "100vh",
                justifyContent: "center",
                justifyItems: "center",
              }}
            >
              <canvas hidden id="canvasID" style={{ overflow: "hidden" }}>
                Canvas not supported
              </canvas>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  {" "}
                  <div
                    ref={mapContainer}
                    style={{
                      width: "585px",
                      height: "765px",
                      position: "relative",
                      borderRadius: "10px",
                    }}
                  >
                    <canvas id="cv">Canvas not supported</canvas>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Test;
