import * as React from 'react'
import { Grid, Container, Card } from '@mui/material'
import dayjs from 'dayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import Box from '@mui/material/Box'
import Slider from '@mui/material/Slider'
import Dustdata from './dust_k_ori.json'
import Typography from '@mui/material/Typography'
import mapboxgl from 'mapbox-gl'
import MapboxLanguage from '@mapbox/mapbox-gl-language'
import 'mapbox-gl/dist/mapbox-gl.css'
import { TemperatureMap } from '../tempMap/temperatureMap.js'
import djdata from './dj-states.json'
import Api from '../Api/Api'
import img1 from '../asset/img/numberImage/1.png'
import img2 from '../asset/img/numberImage/2.png'
import img3 from '../asset/img/numberImage/3.png'
import img4 from '../asset/img/numberImage/4.png'
import img5 from '../asset/img/numberImage/5.png'
import img6 from '../asset/img/numberImage/6.png'
import img7 from '../asset/img/numberImage/7.png'
import img8 from '../asset/img/numberImage/8.png'
import img9 from '../asset/img/numberImage/9.png'
import Linear from '../Progress/Linear.jsx'
import { experimentalStyled as styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import colorbarImg from '../asset/img/colorbar3.png'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  marginBottom: 8,
}))

mapboxgl.accessToken = 'pk.eyJ1IjoicmphdGtkdWRnaCIsImEiOiJjbGU1ZXBxcncwOWxwM29xaW9mYjYxeTczIn0.DECoKxZyn5ye2las_bwxkQ'
let DongListTemp = []
Dustdata.map((item) => DongListTemp.push(item.dongName))
const DongNameList1 = DongListTemp.filter((element, index) => {
  return DongListTemp.indexOf(element) === index
})
DongNameList1.sort((a, b) => (a < b ? -1 : 1))
const marks = [
  {
    value: 1,
    label: '1시간 후',
  },
  {
    value: 12,
    label: '12시간 후',
  },
  {
    value: 24,
    label: '24시간후',
  },
]
function DustCar() {
  const [linerProgress, setLinerProgress] = React.useState(false)
  function valuetext(value) {
    return `${value}:00`
  }
  const today = new Date()
  const year = today.getFullYear()
  const month = ('0' + (today.getMonth() + 1)).slice(-2)
  const day = ('0' + today.getDate()).slice(-2)
  let time = ('0' + (today.getHours() - 1)).slice(-2)
  const minute = today.getMinutes()
  if (minute <= 40) {
    time = ('0' + (today.getHours() - 2)).slice(-2)
  }
  const now_dateString = year + month + day
  const now_clockString = time

  const [dateReal, setDateReal] = React.useState(now_dateString)
  const [timeReal, setTimeReal] = React.useState(now_clockString)

  const [testmap, setTestmap] = React.useState()
  const [cvTextTime, setCvTextTime] = React.useState('')
  const [cvTextDate, setCvTextDate] = React.useState('')

  const [responeDataList, setResponeDataList] = React.useState([])

  const [dustRankList, setDustRankList] = React.useState()

  const [TestDust, setTestDust] = React.useState([])

  const mapContainer = React.useRef()
  // const baseLonlat = [127.55518239819488, 36.29195798555894];
  const baseLonlat = [127.3961597522, 36.3379512241]
  // setTestDust(getdustPredictionData.dust_prediction)

  const getDustPredictionData = async (param) => {
    // const paramFormat = dayjs(param).format("YYYYMMDDHH");
    const paramFormat = param

    try {
      setLinerProgress(true)
      let dateParam = { p_date: param }
      const responseData = await Api.post('DUST/dustPredictionData', dateParam, {
        withCredentials: true,
      })
      if (responseData.data.length < 1) {
        setLinerProgress(false)
        return alert(
          `${paramFormat} 데이터가 없습니다. 최소 선택 시간은 현재 시간으로부터 2시간 전입니다. 다른 날짜와 시간을 선택해 주세요.`
        )
      }
      // console.log("responseData.data ", responseData.data);
      await setResponeDataList(responseData.data)

      // const dustdata = await responseData.data[0].d_data.replaceAll("\\", "");
      const dustPredictiondata = JSON.parse(responseData.data[responseData.data.length - 1].d_p_data)
      await setTestDust(dustPredictiondata)

      setLinerProgress(false)
      // setTestDust(getdustPredictionData.dust_prediction)
    } catch (e) {
      setLinerProgress(false)
      // alert("통신 오류 입니다.");
      // console.log(e);
    }
  }

  // React.useEffect(() => {
  //   // console.log("respeneDataList :", responeDataList);
  // }, [dustRankList]);
  React.useEffect(() => {
    getDustPredictionData(dateReal + timeReal)
    setCvTextDate(dateReal)
    // console.log("respeneDataList :", responeDataList);
  }, [dateReal, timeReal])

  React.useEffect(() => {
    if (TestDust.length > 1) {
      const canvas = document.getElementById('canvasID')
      const ctx = canvas.getContext('2d')
      const drw0 = new TemperatureMap(ctx)
      // const bounds = [
      //   [126.9092734199774, 36.10044806795705],
      //   [127.88697998365123, 36.55580233610119],
      // ];
      const bounds = [
        [126.6092734199774, 35.80044806795705],
        [128.15697998365123, 36.85580233610119],
      ]
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        // style: "mapbox://styles/mapbox/outdoors-v11",
        style: 'mapbox://styles/mapbox/light-v11',
        // center: [127.40518239819488, 36.332195798555894],
        center: baseLonlat,
        maxBounds: bounds,
        zoom: 7,
        // pitch: 35,
        // bearing: 80,
      })
      setTestmap(map)
      const language = new MapboxLanguage({ defaultLanguage: 'ko' })
      map.addControl(language)
      // Add markers to the map.
      //범례 그리기
      const cv = document.getElementById('cv')
      const ctx1 = cv.getContext('2d', { willReadFrequently: true })
      ctx1.canvas.height = 70 //or your desired height
      ctx1.canvas.width = 300 // or your desired width
      ctx1.canvas.style.imageRendering = 'auto' //default
      const image = new Image()
      image.src = `${colorbarImg}`
      ctx1.clearRect(0, 0, 150, 150)
      if (ctx1 && image) {
        image.onload = function () {
          ctx1.drawImage(image, 0, 0, cv.width, cv.height)
        }
      }
      let testarray = []
      let width = map.getCanvas().clientWidth
      let height = map.getCanvas().clientHeight
      canvas.width = width
      canvas.height = height
      TestDust.map((data1) => {
        const test1 = map.project(data1.lonlat)
        const testObj = {
          x: parseInt(test1.x),
          y: parseInt(test1.y),
          value: data1.pm10Value,
        }
        testarray.push(testObj)
      })
      let DustRank = TestDust.sort(function (a, b) {
        return b.pm10Value - a.pm10Value
      })
      let DustRankTop10 = DustRank.filter(function (_, index) {
        return index < 9
      })

      setDustRankList(DustRankTop10)

      // Add markers to the map.

      if (dustRankList !== undefined) {
        // console.log('dustRankList2', dustRankList)
        DustRankTop10.map(async (item, index) => {
          // Create a DOM element for each marker.
          const el = document.createElement('div')
          const width1 = 25
          const height1 = 40
          const imageNum = caseInSwitch(index + 1)
          el.key = index
          el.className = 'marker'
          el.style.backgroundImage = `url(${imageNum})`
          el.style.backgroundRepeat = 'no-repeat'
          el.style.width = `${width1}px`
          el.style.height = `${height1}px`
          el.style.backgroundSize = '100%'
          // el.addEventListener("click", () => {
          //   let result = responeImageList.filter(
          //     (value) => value.dongName === item.dongName
          //   );
          //   setSenserdongImage(result);
          //   setSenserdong(item.dongName);
          // });
          new mapboxgl.Marker(el).setLngLat([item.lonlat[0], item.lonlat[1]]).addTo(map)
        })
      }
      // drw0.setRandomPoints(60, width, height);
      drw0.setDataPoints(testarray, width, height)

      drw0.drawLow(5, 8, false, function () {})

      map.on('load', () => {
        // add mapbox terrain dem source for 3d terrain rendering

        map.addSource('canvas-source', {
          type: 'canvas',
          canvas: 'canvasID',
          coordinates: [
            // [127.20757545283216, 36.54148449661634],
            // [127.58697998365123, 36.52580233610119],
            // [127.57371261929131, 36.179242005608735],
            // [127.20092734199774, 36.18044806795705],
            map.getBounds().getNorthWest().toArray(),
            map.getBounds().getNorthEast().toArray(),
            map.getBounds().getSouthEast().toArray(),
            map.getBounds().getSouthWest().toArray(),
          ],
          animate: true,
        })
        map.addLayer({
          id: 'canvas-layer',
          type: 'raster',
          source: 'canvas-source',
        })
        map.addSource('states', {
          type: 'geojson',
          data: djdata,
        })

        // Add a layer showing the state polygons.
        map.addLayer({
          id: 'states-layer',
          type: 'fill',
          source: 'states',
          paint: {
            'fill-color': 'rgba(200, 100, 240, 0)',
            'fill-outline-color': 'rgba(200, 100, 240, 1)',
          },
        })

        // When a click event occurs on a feature in the states layer,
        // open a popup at the location of the click, with description
        // HTML from the click event's properties.
        map.on('click', 'states-layer', (e) => {
          // console.log(e.lngLat);
          let dongName = e.features[0].properties.EMD_NM
          let dongInfo = TestDust.filter((data) => data.dongName === e.features[0].properties.EMD_NM)
          // console.log("dongInfo", dongInfo);
          onPredictionInfoCar(dongInfo)
          new mapboxgl.Popup()
            // .setLngLat(e.lngLat)
            .setLngLat(dongInfo[0].lonlat)
            // .setLngLat([127.4334984437, 36.4319266863])//평촌동 좌표
            .setHTML(
              `<table> 
                        <tr>
                            <td>${dongName}</td>
                        </tr>
                        <tr>
                            <td>미세먼지</td>
                            <td>:</td>
                            <td>${dongInfo[0].pm10Value.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td>초미세먼지</td>
                            <td>:</td>
                            <td>${dongInfo[0].pm25Value.toFixed(2)}</td>
                        </tr>
              </table>`
            )
            .addTo(map)
        })

        // Change the cursor to a pointer when
        // the mouse is over the states layer.
        map.on('mouseenter', 'states-layer', () => {
          map.getCanvas().style.cursor = 'pointer'
        })

        // Change the cursor back to a pointer
        // when it leaves the states layer.
        map.on('mouseleave', 'states-layer', () => {
          map.getCanvas().style.cursor = ''
        })
      })

      // cleanup function to remove map on unmount
      return () => map.remove()
    }
  }, [TestDust])
  function caseInSwitch(val) {
    var answer = 1
    switch (val) {
      case 2:
        answer = img2
        break
      case 3:
        answer = img3
        break
      case 4:
        answer = img4
        break
      case 5:
        answer = img5
        break
      case 6:
        answer = img6
        break
      case 7:
        answer = img7
        break
      case 8:
        answer = img8
        break
      case 9:
        answer = img9
        break
      default:
        answer = img1
        break
    }
    return answer
  }
  //소수 추출
  function isPrime(num) {
    // 소수는 1과 자기 자신만으로만 나누어 떨어지는 수 임으로
    // num > i
    for (let i = 2; num > i; i++) {
      if (num % i === 0) {
        //이 부분에서 num이  다른 수로 나눠떨어진다면 소수가 아님
        return false
      }
    }
    // 소수는 1보다 큰 정수임으로
    // 1보다 작으면 false를 리턴한다
    return num > 1
  }
  //켈린더 날짜 선택
  const dateRealValue = (param) => {
    const dateFormat = dayjs(param).format('YYYYMMDD')
    const timeFormat = dayjs(param).format('YYYYMMDDHH').slice(-2)
    setTimeReal(timeFormat)
    setDateReal(dateFormat)
    setCvTextDate('')
    setCvTextTime('')
    console.log('car dateFormat :', dateFormat)
    console.log('car timeFormat :', timeFormat)

    // setDateReal(e.target.value)
  }

  //slider value
  function valuetext(value) {
    // console.log("valuetext ",value);
    return `${value}:00`
  }

  //slider 시간 선택
  function onSliderTimeChange(e) {
    if (responeDataList[e.target.value - 1] !== undefined) {
      const dustdata = JSON.parse(responeDataList[e.target.value - 1].d_p_data)
      setCvTextDate(responeDataList[e.target.value - 1].date)
      setCvTextTime(e.target.value - 1)
      setTestDust(dustdata)
    }
  }
  const onPredictionInfoCar = (info, info2) => {
    let dustData = null
    if (typeof info === 'string') {
      dustData = TestDust.find((e) => e.dongName === info)
      if (dustData == null || dustData === undefined) {
        return
      }

      const popup = new mapboxgl.Popup()
        // .setLngLat(e.lngLat)
        // .setLngLat(dongInfo[0].lonlat)
        .setLngLat(dustData.lonlat) //평촌동 좌표
        .setHTML(
          `<table> 
            <tr>
                <td>${dustData.dongName}</td>
            </tr>
            <tr>
                <td>미세먼지</td>
                <td>:</td>
                <td>${dustData.pm10Value.toFixed(2)}</td>
            </tr>
            <tr>
                <td>초미세먼지</td>
                <td>:</td>
                <td>${dustData.pm25Value.toFixed(2)}</td>
            </tr>
        </table>`
        )

      const popups = document.getElementsByClassName('mapboxgl-popup')

      if (popups.length) {
        popups[0].remove()
      }
      popup.addTo(testmap)
    } else if (info[0] == null || info[0] === undefined || info == null || info === undefined) {
      return console.log('onPredictionInfoCar info null')
    } else {
      dustData = TestDust.find((e) => e.dongName === info[0].dongName)
    }
  }

  return (
    <div>
      <Box>
        <Container>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3.5} alignItems="center" justify="center">
              <Grid item sx={{ marginBottom: 1, marginLeft: 1, marginRight: 1 }}>
                <Card>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: 'Pretendard',
                      fontSize: 16,
                      fontWeight: 600,
                      textAlign: 'left',
                      mt: 2,
                      ml: 3.5,
                      color: '#303030',
                    }}
                  >
                    예측 날짜 시간
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      sx={{
                        m: 1,
                        marginBottom: 1,
                        marginLeft: 1,
                        marginRight: 1,
                      }}
                      components={['DateTimePicker', 'DateTimePicker']}
                    >
                      <div>
                        <DateTimePicker
                          sx={{
                            m: 1,
                          }}
                          // maxDate={max_today} //오늘날짜 기준 이상 선택안됨
                          defaultValue={dayjs().add(-2, 'hours')}
                          format="YYYY-MM-DD HH"
                          label="예측 기준 시간 선택"
                          // viewRenderers={{
                          //   hours: renderTimeViewClock,
                          // }}
                          views={['year', 'month', 'day', 'hours']}
                          onChange={(newValue) => dateRealValue(newValue)}
                          disableFuture
                          minDateTime={dayjs().add(-1, 'days')}
                          maxDateTime={dayjs().add(-2, 'hours')}
                        />
                      </div>
                    </DemoContainer>
                  </LocalizationProvider>
                  <Box sx={{ m: 2 }}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: 'Pretendard',
                        fontSize: 16,
                        fontWeight: 600,
                        textAlign: 'left',
                        ml: 1.5,
                        mb: 4,
                        color: '#303030',
                      }}
                    >
                      시간 선택
                    </Typography>
                    <Box sx={{ ml: 5.5, mr: 5.5 }}>
                      <Slider
                        aria-label="Always visible"
                        defaultValue={1}
                        getAriaValueText={valuetext}
                        step={1}
                        max={24}
                        min={1}
                        marks={marks}
                        valueLabelDisplay="on"
                        onChange={onSliderTimeChange}
                      />
                    </Box>
                  </Box>
                </Card>
              </Grid>

              <Grid item sx={{ m: 1 }}>
                <Card></Card>
              </Grid>
              {/* <Grid item sx={{ m: 1 }}>
                <Card>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Pretendard",
                      fontSize: 16,
                      fontWeight: 600,
                      textAlign: "left",
                      mt: 2,
                      ml: 3.5,
                      mb: 2,
                      color: "#303030",
                    }}
                  >
                    동 이름 검색
                  </Typography>
                  <Autocomplete
                    disablePortal
                    sx={{ ml: 3, mr: 3.3, mb: 3 }}
                    id="combo-box-demo"
                    defaultValue={autocomplete_value}
                    options={DongNameList1}
                    value={autocomplete_value || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.dongName === value.dongName
                    }
                    onKeyDown={(e) => {
                      if (e.code === "enter" && e.target.value) {
                        setAutocomplete_value(
                          autocomplete_value.concat(e.target.value)
                        );
                      }
                    }}
                    onChange={(_, newValue) => {
                      if (newValue !== null) {
                        setAutocomplete_value(newValue);
                        onPredictionInfoCar(newValue);
                      }
                    }}
                    inputValue={autocomplete_value}
                    onInputChange={(_, newInputValue) => {
                      if (newInputValue !== null) {
                        setAutocomplete_value(newInputValue);
                        onPredictionInfoCar(newInputValue);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="동이름 검색" />
                    )}
                  />
                </Card>
              </Grid> */}
              <Grid item sx={{ m: 1 }}>
                <Card sx={{ height: 425 }}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: 'Pretendard',
                      fontSize: 16,
                      fontWeight: 600,
                      textAlign: 'left',
                      mt: 2,
                      ml: 3.5,
                      mb: 2,
                      color: '#303030',
                    }}
                  >
                    미세먼지 순위
                  </Typography>
                  {dustRankList !== undefined ? (
                    dustRankList.map((item, index) => {
                      return (
                        <div key={index}>
                          <Box
                            sx={{
                              border: 1,
                              borderRadius: '8px',
                              mb: '5px',
                              ml: 2,
                              mr: 2,
                              borderColor: '#F6F6F6',
                              height: '35px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Typography>
                              {index + 1}.{item.dongName} 미세먼지:
                              {item.pm10Value.toFixed(2)}㎍/m³
                            </Typography>
                          </Box>
                        </div>
                      )
                    })
                  ) : (
                    <div> 미세먼지 높은 동 순위</div>
                  )}
                </Card>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={8.5} alignItems="center" justify="center">
              <Item>
                <Grid container>
                  <Grid item sm={2} xs={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: 'Pretendard',
                        fontSize: 18,
                        fontWeight: 600,
                        textAlign: 'left',
                        ml: 1.5,
                        color: '#3B4056',
                      }}
                    >
                      예측 기준 날짜
                    </Typography>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: 'Pretendard',
                        fontSize: 18,
                        fontWeight: 400,
                        textAlign: 'left',
                        color: '#909090',
                      }}
                    >
                      {dateReal} {timeReal}시 {cvTextTime + 1}시간 후
                    </Typography>
                  </Grid>
                  <Grid item sm={1}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: 'Pretendard',
                        fontSize: 18,
                        fontWeight: 400,
                        textAlign: 'left',
                        color: '#E8E8E8',
                      }}
                    >
                      |
                    </Typography>
                  </Grid>
                  <Grid item sm={1} xs={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: 'Pretendard',
                        fontSize: 18,
                        fontWeight: 600,
                        textAlign: 'left',
                        color: '#3B4056',
                      }}
                    >
                      예측
                    </Typography>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: 'Pretendard',
                        fontSize: 18,
                        fontWeight: 400,
                        textAlign: 'left',
                        color: '#909090',
                      }}
                    >
                      {cvTextDate.substr(0, 8)} {cvTextDate.substr(8, 2)} 시 데이터
                    </Typography>
                  </Grid>
                </Grid>
              </Item>
              <Card>
                <Grid container xs={12}>
                  <Card className="m-auto" sx={{ m: 1, minHeight: 600, maxHeight: 789 }}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: 'Pretendard',
                        fontSize: 18,
                        fontWeight: 600,
                        textAlign: 'left',
                        mt: 3,
                        ml: 3.5,
                        color: '#3B4056',
                      }}
                    >
                      지도 데이터
                    </Typography>
                    <Box sx={{ m: 2 }}>
                      <div id="container_test2">
                        {linerProgress ? <Linear></Linear> : null}
                        <div id="container_test">
                          <Grid container direction="row" justifyContent="center" alignItems="center">
                            <Box
                              sx={{
                                width: '100vw',
                                height: '100vh',
                                justifyContent: 'center',
                                justifyItems: 'center',
                              }}
                            >
                              <canvas hidden id="canvasID" style={{ overflow: 'hidden' }}>
                                Canvas not supported
                              </canvas>
                              <Grid container direction="row" justifyContent="center" alignItems="center">
                                <div
                                  ref={mapContainer}
                                  style={{
                                    width: '780px',
                                    height: '690px',
                                    position: 'relative',
                                    borderRadius: '10px',
                                  }}
                                >
                                  <canvas id="cv">Canvas not supported</canvas>
                                </div>
                              </Grid>
                            </Box>
                          </Grid>
                        </div>
                      </div>
                    </Box>
                  </Card>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  )
}

export default DustCar
