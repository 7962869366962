import * as React from "react";
import { useDispatch } from "react-redux";
import { getDustMainParam } from "../modules/redux_dust";
import Api from "../Api/Api";
import dayjs, { Dayjs } from "dayjs";
import { Box, Grid, Link, Typography,Container } from "@mui/material";
import mainImg from "../asset/img/main1.png";
import backgroundImg from "../asset/img/background.png";
import main_total from "../asset/img/main_total.png";
import main_analyze from "../asset/img/main_analyze.png";
import main_result from "../asset/img/main_result.png";
import { useNavigate } from "react-router-dom";
function DustMain() {
  const navigate = useNavigate();
  const Dayjs = dayjs();
  const today = new Date();
  const year = today.getFullYear();
  const month = ("0" + (today.getMonth() + 1)).slice(-2);
  const day = ("0" + today.getDate()).slice(-2);
  let time = ("0" + (today.getHours() - 1)).slice(-2);
  const minute = today.getMinutes();
  if (minute <= 40) {
    time = ("0" + (today.getHours() - 2)).slice(-2);
  }
  // console.log("current_time  :", current_time);
  // const max_today = dayjs("20230727");
  const max_today = dayjs(year + month + day);
  const now_dateString = year + month + day + time + "00";

  const dispatch = useDispatch();
  const ongetDustMainParam = (param) => dispatch(getDustMainParam(param));
  React.useEffect(() => {
    getDustRealData();
  }, []);
  const getDustRealData = async () => {
    try {
      let dateParam = { date: now_dateString };
      const responseData = await Api.post("DUST/dustRealData", dateParam, {
        withCredentials: true,
      });
      // const dustdata = await responseData.data[0].d_data.replaceAll("\\", "");
      const dustdata = await JSON.parse(responseData.data[0].d_data);

      ongetDustMainParam(dustdata);
    } catch (e) {
      console.log(e);
      // alert("통신 요청 실패, 새로고침 해주세요");
      // console.log(e.response.status);
    }
  };
  const onClick_total = () => {
    navigate("/dust_total");
    window.scrollTo(0, 0);
  };
  const onClick_analyze = () => {
    navigate("/dust_analyze");
    window.scrollTo(0, 0);
  };
  const onClick_result = () => {
    navigate("/dust_result");
    window.scrollTo(0, 0);
  };

  // console.log("Login getprofile : ", getprofile);

  return (
    <div>
        <Box>
          <Typography
            variant="body2"
            sx={{
              mt: 10,
              fontSize: 48,
              fontFamily: "SUIT",
              fontWeight: 200,
              color: "#1A335B",
              lineHeight: "52.8px",
            }}
          >
            미세먼지를 예측하는 스마트한 방법
          </Typography>
        </Box>
      <Container xs={12}>
        <Box>
          <Typography
            variant="body2"
            sx={{
              fontSize: 48,
              fontFamily: "SUIT",
              fontWeight: 700,
              color: "#1A335B",
              lineHeight: "52.8px",
            }}
          >
            미세먼지 예측 모니터링 시스템
          </Typography>
        </Box>
      </Container>
      <Box>
        <Typography
          variant="body2"
          sx={{
            mt: 5,
            fontSize: 20,
            fontFamily: "SUIT",
            fontWeight: 500,
            color: "#1A335B",
            lineHeight: "26px",
            opacity: 0.7,
          }}
        >
          대덕특구 미세먼지 예측 모니터링 시스템으로 관리하세요.
        </Typography>
      </Box>
      <Box
        sx={{
          mt: 10,
          backgroundImage: `url(${backgroundImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <img
          className="mainImg"
          width="35%"
          height="auto"
          src={mainImg}
          alt="mainImg"
        />
      </Box>
      <Box sx={{ mt: 1, p: 1 }}>
        <Box display={"flex"} justifyContent={"flex-start"} sx={{ ml: 5.5 }}>
          <Typography
            variant="body2"
            color="#1A335B"
            sx={{
              fontWeight: 700,
              fontSize: 20,
              fontFamily: "paybooc",
            }}
          >
            System
          </Typography>
        </Box>

        <Grid container sx={{ mb: 15, mt: 2 }}>
          <Grid item sm={4} xs={12}>
            <Box display={"flex"} justifyContent={"flex-start"} sx={{ ml: 5 }}>
              <Typography
                variant="body2"
                color="#303030"
                sx={{
                  fontWeight: 200,
                  fontSize: 36,
                  lineHeight: "39.6px",
                  fontFamily: "SUIT",
                  alignContent: "flex-start",
                }}
              >
                뛰어난 기술역량을 바탕으로
              </Typography>
            </Box>
            <Box display={"flex"} justifyContent={"flex-start"} sx={{ ml: 5 }}>
              <Typography
                variant="body2"
                color="#303030"
                sx={{
                  fontWeight: 600,
                  fontSize: 36,
                  fontFamily: "SUIT",
                  lineHeight: "39.6px",
                  alignContent: "flex-start",
                }}
              >
                분석부터 예측까지
              </Typography>
            </Box>
          </Grid>
          <Grid item sm={8} xs={12}>
            <Grid container spacing={2} columns={12}>
              <Grid item xs={12} sm={3.7}>
                <Box
                  sx={{ width: "100%", height: 275, background: "#F7F8FB" }}
                  onClick={onClick_total}
                >
                  <Box display={"flex"} justifyContent={"flex-start"}>
                    <Typography
                      variant="body2"
                      color="#303030"
                      sx={{
                        marginTop: 5,
                        marginLeft: 5,
                        fontWeight: 600,
                        fontSize: 24,
                        fontFamily: "SUIT",
                        lineHeight: "26.4px",
                      }}
                    >
                      현황 및 통계
                    </Typography>
                  </Box>
                  <Box
                    sx={{ mt: 7 }}
                    display={"flex"}
                    justifyContent={"flex-end"}
                  >
                    <img id="main_icon" src={main_total} alt="main_total" />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3.7}>
                <Box
                  sx={{ width: "100%", height: 275, background: "#F7F8FB" }}
                  onClick={onClick_analyze}
                >
                  <Box display={"flex"} justifyContent={"flex-start"}>
                    <Typography
                      variant="body2"
                      color="#303030"
                      sx={{
                        marginTop: 5,
                        marginLeft: 5,
                        fontWeight: 600,
                        fontSize: 24,
                        fontFamily: "SUIT",
                        lineHeight: "26.4px",
                      }}
                    >
                      발생원 분석
                    </Typography>
                  </Box>
                  <Box
                    sx={{ mt: 7 }}
                    display={"flex"}
                    justifyContent={"flex-end"}
                  >
                    <img id="main_icon" src={main_analyze} alt="main_analyze" />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3.7} marginRight={1}>
                <Box
                  sx={{ width: "100%", height: 275, background: "#F7F8FB" }}
                  onClick={onClick_result}
                >
                  <Box display={"flex"} justifyContent={"flex-start"}>
                    <Typography
                      variant="body2"
                      color="#303030"
                      sx={{
                        marginTop: 5,
                        marginLeft: 5,
                        fontWeight: 600,
                        fontSize: 24,
                        fontFamily: "SUIT",
                        lineHeight: "26.4px",
                      }}
                    >
                      예측결과분석
                    </Typography>
                  </Box>
                  <Box
                    sx={{ mt: 7 }}
                    display={"flex"}
                    justifyContent={"flex-end"}
                  >
                    <img id="main_icon" src={main_result} alt="main_result" />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default DustMain;
