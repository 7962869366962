import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { Button, Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { getProfileParam } from "../modules/redux_login";
import Api from "../Api/Api";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import login_background from "../asset/img/login_background.png";
import Typography from "@mui/material/Typography";
const InputTextField = styled(TextField)({
  "& label": {
    // placeholder text color
    color: "#BCBDC4",
  },
  "& label.Mui-focused": {
    // 해당 input focus 되었을 때 placeholder text color
    // floatng label을 사용할 때 처리 필요하다
    color: "#BCBDC4",
  },
  "& label.Mui-error": {
    color: "#d32f2f",
  },
  "& .MuiOutlinedInput-root": {
    color: "#ffffff",
    "& fieldset": {
      borderColor: "#BCBDC4",
    },
  },
});

function Login() {
  const { getprofile } = useSelector((state) => ({
    profile: state.funGetprofile.profile,
  }));

  const dispatch = useDispatch();
  const ongetProfileParam = (param) => dispatch(getProfileParam(param));
  const [inputId, setInputId] = useState("");
  const [inputPw, setInputPw] = useState("");

  const navigate = useNavigate();
  const { pathname } = useLocation();
  // input data 의 변화가 있을 때마다 value 값을 변경해서 useState 해준다
  const handleInputId = (e) => {
    setInputId(e.target.value);
  };

  const handleInputPw = (e) => {
    setInputPw(e.target.value);
  };
  const onKeyPress_1 = (e) => {
    if (e.key === "Enter") {
      onClickLogin(e);
    }
  };

  // login 버튼 클릭 이벤트
  const onClickLogin = async (e) => {
    e.preventDefault();

    let loginParam = {
      userID: inputId,
      userPW: inputPw,
    };
    try {
      const response = await Api.post("DUST/login", loginParam, {
        withCredentials: true,
      });
      if (response.status === 200) {
        alert("로그인성공");

        secureLocalStorage.setItem("userID", response.data.userID);
        secureLocalStorage.setItem(
          "userAuthority",
          response.data.userAuthority
        );
        secureLocalStorage.setItem("idx", response.data.idx);

        ongetProfileParam(response.data);
        navigate("/");
      }
    } catch (e) {
      alert("로그인실패");
      // console.log(e.response.status);
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        alignContent: "center",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
        backgroundImage: `linear-gradient(90.39deg, #1A2737 0.33%, rgba(26, 39, 55, 0) 99.66%) ,url(${login_background})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        marginTop: "-8px",
      }}
    >
      <Grid container justifyContent={"center"} alignItems={"center"}>
        <Grid item>
          <Box component="form">
            <Typography
              variant="body2"
              sx={{
                fontFamily: "SUIT",
                fontSize: 32,
                fontWeight: 900,
                textAlign: "left",
                color: "#FFFFFF",
                mt: 5,
              }}
            >
              Log in
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontFamily: "SUIT",
                fontSize: 16,
                fontWeight: 500,
                textAlign: "left",
                color: "#FFFFFF",
                mt: 1,
              }}
            >
              서비스 이용을 위해 로그인 해주세요.
            </Typography>
            <div style={{ marginTop: "80px" }}>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "SUIT",
                  fontSize: 18,
                  fontWeight: 500,
                  textAlign: "left",
                  color: "#FFFFFF",
                }}
              >
                아이디
              </Typography>
              <InputTextField
                placeholder="아이디를 입력하세요"
                variant="outlined"
                onChange={handleInputId}
                sx={{ width: 330, height: 54, borderRadius: 2, mt: 0.7 }}
              />
            </div>
            <div style={{ marginTop: "30px" }}>
              <Typography
                variant="body2"
                sx={{
                  fontFamily: "SUIT",
                  fontSize: 18,
                  fontWeight: 500,
                  textAlign: "left",
                  color: "#FFFFFF",
                }}
              >
                비밀번호
              </Typography>
              <InputTextField
                id="outlined-basic"
                placeholder="비밀번호를 입력하세요"
                variant="outlined"
                type="password"
                onChange={handleInputPw}
                onKeyDown={(e)=>onKeyPress_1(e)}
                sx={{ width: 330, height: 54, borderRadius: 2, mt: 0.7 }}
              />
            </div>
            <div style={{ marginTop: "60px" }}>
              <Button
                sx={{
                  width: 330,
                  height: 54,
                  borderRadius: 2,
                  fontFamily: "SUIT",
                  fontSize: 16,
                }}
                variant="contained"
                onClick={onClickLogin}
              >
                로그인
              </Button>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Login;
