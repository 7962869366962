import * as React from "react";
import { Grid, Container, Card } from "@mui/material";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
// import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Dustdata from "./dust_k_ori.json";
import Typography from "@mui/material/Typography";
import GraphPie from "../Graph/GraphPie";
import { useSelector, useDispatch } from "react-redux";
import {
  getDustPredictionParam,
  getDustPredictionListParam,
} from "../modules/redux_dust_prediction";
// import { getWeatherMainParam } from "../modules/redux_weather";
import mapboxgl from "mapbox-gl";
import MapboxLanguage from "@mapbox/mapbox-gl-language";
import "mapbox-gl/dist/mapbox-gl.css";
import { TemperatureMap } from "../tempMap/temperatureMap.js";
import djdata from "./dj-states.json";
import markerImg from "../asset/img/location1.png";
import markerImg2 from "../asset/img/location2.png";

import Api from "../Api/Api";
import senserDongName from "./senserDongName.json";
import { CardMedia } from "@mui/material";
import Linear from "../Progress/Linear.jsx";

import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import colorbarImg from "../asset/img/colorbar3.png";
import markerTextImg from "../asset/img/markerTextImg.png";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  marginBottom: 8,
}));
mapboxgl.accessToken =
  "pk.eyJ1IjoicmphdGtkdWRnaCIsImEiOiJjbGU1ZXBxcncwOWxwM29xaW9mYjYxeTczIn0.DECoKxZyn5ye2las_bwxkQ";
let DongListTemp = [];
Dustdata.map((item) => DongListTemp.push(item.dongName));
const DongNameList1 = DongListTemp.filter((element, index) => {
  return DongListTemp.indexOf(element) === index;
});
DongNameList1.sort((a, b) => (a < b ? -1 : 1));
const marks = [
  {
    value: 1,
    label: "1시간 후",
  },
  {
    value: 12,
    label: "12시간 후",
  },
  {
    value: 24,
    label: "24시간후",
  },
];
function DustPrediction() {
  const [linerProgress, setLinerProgress] = React.useState(false);
  function valuetext(value) {
    return `${value}:00`;
  }

  const Dayjs = dayjs();
  const today = new Date();
  const year = today.getFullYear();
  const month = ("0" + (today.getMonth() + 1)).slice(-2);
  const day = ("0" + today.getDate()).slice(-2);
  let time = ("0" + (today.getHours() - 1)).slice(-2);
  const minute = today.getMinutes();
  if (minute <= 40) {
    time = ("0" + (today.getHours() - 2)).slice(-2);
  }
  // const max_today = dayjs("20230727");
  const max_today = dayjs(year + month + day + time);
  // console.log("minute : ", minute);
  // const current_time = Dayjs.format("HH");
  // console.log("current_time  :", current_time);
  const now_dateString = year + month + day;
  // const now_dateString = "2023072701";

  const now_date1String = year + month + day;
  // const now_date1String = "20230727";

  const now_clockString = time;
  // const now_clockString = "01";

  // console.log(" now_dateString:", now_dateString);
  // console.log(" now_clockString:", now_clockString);

  const [dateReal, setDateReal] = React.useState(now_dateString);
  const [timeReal, setTimeReal] = React.useState(now_clockString);

  const [testmap, setTestmap] = React.useState();
  const [cvTextTime, setCvTextTime] = React.useState("");
  const [cvTextDate, setCvTextDate] = React.useState("");

  const [autocomplete_value, setAutocomplete_value] = React.useState("");
  const [autocomplete_input_value, setAutocomplete_input_value] =
    React.useState("");

  const [responeDataList, setResponeDataList] = React.useState([]);
  const [responeImageList, setResponeImageList] = React.useState([]);

  const [weatherData1, setweatherData] = React.useState(autocomplete_value);

  const [senserdong, setSenserdong] = React.useState();
  const [senserdongImage, setSenserdongImage] = React.useState();
  React.useEffect(() => {
    // console.log("now_date1String : ", now_date1String);
    // console.log("now_clockString : ", now_clockString);
    getDustPredictionImage(dateReal, timeReal);
    // console.log("respeneDataList :", responeDataList);
  }, [responeDataList]);

  const dispatch = useDispatch();
  const ongetDustPredictionParam = (param) =>
    dispatch(getDustPredictionParam(param));
  const ongetDustPredictionListParam = (param) =>
    dispatch(getDustPredictionListParam(param));
  // const ongetWeatherMainParam = (param) => dispatch(getWeatherMainParam(param));

  const getDustPredictionData = async (param) => {
    // const paramFormat = dayjs(param).format("YYYYMMDDHH");
    const paramFormat = param;
    try {
      setLinerProgress(true);
      let dateParam = { p_date: param };

      const responseData = await Api.post(
        "DUST/dustPredictionData",
        dateParam,
        {
          withCredentials: true,
        }
      );
      if (responseData.data.length < 1) {
        setDateReal(now_dateString);
        setTimeReal(now_clockString - 1);
        setCvTextDate("");
        setCvTextTime("");
        return alert(
          `${paramFormat} 데이터가 없습니다. 최소 선택 시간은 현재 시간으로부터 2시간 전입니다. 다른 날짜와 시간을 선택해 주세요.`
        );
      }
      // console.log("responseData.data ", responseData.data);
      setResponeDataList(responseData.data);
      ongetDustPredictionListParam(responseData.data);
      // const dustdata = await responseData.data[0].d_data.replaceAll("\\", "");
      const dustPredictiondata = JSON.parse(
        responseData.data[responseData.data.length - 1].d_p_data
      );
      ongetDustPredictionParam(dustPredictiondata);
      setLinerProgress(false);
    } catch (e) {
      setLinerProgress(false);
      // alert("통신 오류 입니다.");
      // console.log(e);
    }
  };
  const getDustPredictionImage = async (dateParam, clockParam) => {
    const postObject = {
      date: dateParam,
      clock: clockParam,
    };
    try {
      setLinerProgress(true);
      const responseData = await Api.post(
        "DUST/dustPredictionImage",
        postObject,
        {
          withCredentials: true,
        }
      );
      if (responseData.data.length < 1) {
        setLinerProgress(false);
        setDateReal(now_dateString);
        setTimeReal(now_clockString);
        setCvTextDate("");
        setCvTextTime("");
        return alert(
          `${dateParam} ${clockParam}시 데이터가 없습니다. 최소 선택 시간은 현재 시간으로부터 2시간 전입니다. 다른 날짜와 시간을 선택해 주세요.`
        );
      }
      // console.log("postObject ", postObject);

      // console.log("responseData.data ", responseData.data);

      setResponeImageList(responseData.data);
      setLinerProgress(false);
      // console.log("responeImageList :", responeImageList);
      // const dustdata = await responseData.data[0].d_data.replaceAll("\\", "");
    } catch (e) {
      setLinerProgress(false);
      // alert("이미지 통신 오류 입니다.");
      // console.log(e);
    }
  };
  const getdustPredictionList = useSelector((state) => ({
    dust_prediction_list: state.funGetDustPrediction.dust_prediction_list,
  }));

  const getdustPredictionData = useSelector((state) => ({
    dust_prediction: state.funGetDustPrediction.dust_prediction,
  }));
  const mapContainer = React.useRef();
  // const baseLonlat = [127.55518239819488, 36.29195798555894];
  const baseLonlat = [127.3961597522, 36.3379512241];

  let TestDust = getdustPredictionData.dust_prediction;

  // React.useEffect(() => {
  //   getDustPredictionData(dateReal);
  //   setResponeDataList(getdustPredictionList.dust_prediction_list);

  //   // console.log("use effect now_dateString :", now_dateString);
  // }, [timeReal]);
  // React.useEffect(() => {
  //   console.log("dateReal : ", dateReal);
  // }, [dateReal]);
  React.useEffect(() => {
    getDustPredictionData(dateReal + timeReal);
    setCvTextDate(dateReal);
    setResponeDataList(getdustPredictionList.dust_prediction_list);
    getDustPredictionImage(dateReal, timeReal);
    // console.log("useEffect datereal :", dateReal);
    // console.log("useEffect timeReal :", timeReal);
    // console.log("useEffect sum :", dateReal + timeReal);
  }, [dateReal, timeReal]);
  React.useEffect(() => {
    // create the map and configure it
    // check out the API reference for more options
    // https://docs.mapbox.com/mapbox-gl-js/api/map/
    if (TestDust.length > 1) {
      const canvas = document.getElementById("canvasID");
      const ctx = canvas.getContext("2d");

      const drw0 = new TemperatureMap(ctx);

      const bounds = [
        [126.6092734199774, 35.80044806795705],
        [128.15697998365123, 36.85580233610119],
      ];
      const map = new mapboxgl.Map({
        container: mapContainer.current,
        // style: "mapbox://styles/mapbox/outdoors-v11",
        style: "mapbox://styles/mapbox/light-v11",
        // center: [127.40518239819488, 36.332195798555894],
        center: baseLonlat,
        maxBounds: bounds,
        zoom: 7,
        // pitch: 35,
        // bearing: 80,
      });
      setTestmap(map);
      const language = new MapboxLanguage({ defaultLanguage: "ko" });
      map.addControl(language);
      // Add markers to the map.

      // Add markers to the map.
      senserDongName.map((item) => {
        // Create a DOM element for each marker.
        const el = document.createElement("div");
        const width1 = 35;
        const height1 = 35;
        el.className = "marker";
        if (
          item.dong === "tli" ||
          item.dong === "neu_2" ||
          item.dong === "jmi" ||
          item.dong === "bsa_b"
        ) {
          el.style.backgroundImage = `url(${markerImg2})`;
        } else {
          el.style.backgroundImage = `url(${markerImg})`;
        }

        el.style.width = `${width1}px`;
        el.style.height = `${height1}px`;
        el.style.backgroundSize = "100%";

        el.addEventListener("click", () => {
          let result = responeImageList.filter(
            (value) => value.dongName === item.dongName
          );
          setSenserdongImage(result);
          setSenserdong(item.dongName);
          // console.log("result :", result);
          // console.log("senserdong :", senserdong);
          // console.log("senserdongImage :", senserdongImage);
          // console.log("responeImageList click : ", responeImageList);
        });
        new mapboxgl.Marker(el)
          .setLngLat([item.lonlat[0], item.lonlat[1]])
          .addTo(map);
      });

      let testarray = [];
      let width = map.getCanvas().clientWidth;
      let height = map.getCanvas().clientHeight;
      canvas.width = width;
      canvas.height = height;
      TestDust.map((data1) => {
        const test1 = map.project(data1.lonlat);
        const testObj = {
          x: parseInt(test1.x),
          y: parseInt(test1.y),
          value: data1.pm10Value,
        };
        testarray.push(testObj);
      });
      // drw0.setRandomPoints(60, width, height);
      drw0.setDataPoints(testarray, width, height);
      // drw0.drawFull(true, function () {
      //   // drw0.drawPoints();
      // });
      // drw0.drawLow(5, 8, false, function () {}
      drw0.drawLow(5, 8, false, function () {});
      //범례 그리기
      const cv = document.getElementById("cv");
      const ctx1 = cv.getContext("2d", { willReadFrequently: true });
      ctx1.canvas.height = 70; //or your desired height
      ctx1.canvas.width = 300; // or your desired width
      ctx1.canvas.style.imageRendering = "auto"; //default
      const image = new Image();
      image.src = `${colorbarImg}`;
      ctx1.clearRect(0, 0, 150, 150);
      if (ctx1 && image) {
        image.onload = function () {
          ctx1.drawImage(image, 0, 0, cv.width, cv.height);
        };
      }
      map.on("load", () => {
        // add mapbox terrain dem source for 3d terrain rendering

        map.addSource("canvas-source", {
          type: "canvas",
          canvas: "canvasID",
          coordinates: [
            // [127.20757545283216, 36.54148449661634],
            // [127.58697998365123, 36.52580233610119],
            // [127.57371261929131, 36.179242005608735],
            // [127.20092734199774, 36.18044806795705],
            map.getBounds().getNorthWest().toArray(),
            map.getBounds().getNorthEast().toArray(),
            map.getBounds().getSouthEast().toArray(),
            map.getBounds().getSouthWest().toArray(),
          ],
          animate: true,
        });
        map.addLayer({
          id: "canvas-layer",
          type: "raster",
          source: "canvas-source",
        });
        map.addSource("states", {
          type: "geojson",
          data: djdata,
        });

        // Add a layer showing the state polygons.
        map.addLayer({
          id: "states-layer",
          type: "fill",
          source: "states",
          paint: {
            "fill-color": "rgba(200, 100, 240, 0)",
            "fill-outline-color": "rgba(200, 100, 240, 1)",
          },
        });

        // When a click event occurs on a feature in the states layer,
        // open a popup at the location of the click, with description
        // HTML from the click event's properties.
        map.on("click", "states-layer", (e) => {
          // console.log(e.lngLat);
          let dongName = e.features[0].properties.EMD_NM;
          let dongInfo = TestDust.filter(
            (data) => data.dongName === e.features[0].properties.EMD_NM
          );
          // console.log("dongInfo", dongInfo);
          onPredictionInfo(dongInfo);
          new mapboxgl.Popup()
            // .setLngLat(e.lngLat)
            .setLngLat(dongInfo[0].lonlat)
            // .setLngLat([127.4334984437, 36.4319266863])//평촌동 좌표
            .setHTML(
              `<table> 
                        <tr>
                            <td>${dongName}</td>
                        </tr>
                        <tr>
                            <td>미세먼지</td>
                            <td>:</td>
                            <td>${dongInfo[0].pm10Value.toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td>초미세먼지</td>
                            <td>:</td>
                            <td>${dongInfo[0].pm25Value.toFixed(2)}</td>
                        </tr>
              </table>`
            )
            .addTo(map);
        });

        // Change the cursor to a pointer when
        // the mouse is over the states layer.
        map.on("mouseenter", "states-layer", () => {
          map.getCanvas().style.cursor = "pointer";
        });

        // Change the cursor back to a pointer
        // when it leaves the states layer.
        map.on("mouseleave", "states-layer", () => {
          map.getCanvas().style.cursor = "";
        });
      });

      // cleanup function to remove map on unmount
      return () => map.remove();
    }
  }, [TestDust]);

  //켈린더 날짜 선택
  const dateRealValue = (param) => {
    const dateFormat = dayjs(param).format("YYYYMMDD");
    const timeFormat = dayjs(param).format("YYYYMMDDHH").slice(-2);
    setTimeReal(timeFormat);
    setDateReal(dateFormat);
    setCvTextDate("");
    setCvTextTime("");
    // console.log("pre dateFormat", dateFormat);
    // console.log("pre timeFormat", timeFormat);
  };

  //slider value
  function valuetext(value) {
    // console.log("valuetext ",value);
    return `${value}:00`;
  }

  //slider 시간 선택
  function onSliderTimeChange(e) {
    if (responeDataList[e.target.value - 1] !== undefined) {
      const dustdata = JSON.parse(responeDataList[e.target.value - 1].d_p_data);
      setCvTextDate(responeDataList[e.target.value - 1].date);
      setCvTextTime(e.target.value - 1);
      ongetDustPredictionParam(dustdata);
      // console.log(dustdata)
    }
  }
  const onPredictionInfo = (info, info2) => {
    let dustData = null;
    if (typeof info === "string") {
      dustData = TestDust.find((e) => e.dongName === info);
      if (dustData == null || dustData === undefined) {
        return;
      }
      setAutocomplete_value(info);
      setAutocomplete_input_value(info);
      const popup = new mapboxgl.Popup()
        // .setLngLat(e.lngLat)
        // .setLngLat(dongInfo[0].lonlat)
        .setLngLat(dustData.lonlat) //평촌동 좌표
        .setHTML(
          `<table> 
            <tr>
                <td>${dustData.dongName}</td>
            </tr>
            <tr>
                <td>미세먼지</td>
                <td>:</td>
                <td>${dustData.pm10Value.toFixed(2)}</td>
            </tr>
            <tr>
                <td>초미세먼지</td>
                <td>:</td>
                <td>${dustData.pm25Value.toFixed(2)}</td>
            </tr>
        </table>`
        );

      const popups = document.getElementsByClassName("mapboxgl-popup");

      if (popups.length) {
        popups[0].remove();
      }
      popup.addTo(testmap);
    } else if (
      info[0] == null ||
      info[0] === undefined ||
      info == null ||
      info === undefined
    ) {
      return console.log("onPredictionInfo info null");
    } else {
      dustData = TestDust.find((e) => e.dongName === info[0].dongName);
      setAutocomplete_value(info[0].dongName);
      setAutocomplete_input_value(info[0].dongName);
    }
  };
  const imageTable = (
    <table id="prediction_table">
      <tbody>
        <tr id="prediction_tr" align="center" bgcolor="#B38F75">
          <td id="prediction_td" title="현지기압">
            pa
          </td>
          <td id="prediction_td" title="해면기압">
            ps
          </td>
          <td id="prediction_td" title="미세먼지" bgcolor="#2D3A80">
            pm10
          </td>
          <td id="prediction_td" title="초미세먼지" bgcolor="#2D3A80">
            pm25
          </td>
        </tr>
        <tr id="prediction_tr" align="center" bgcolor="#B38F75">
          <td id="prediction_td" title="온도">
            ta
          </td>
          <td id="prediction_td" title="습도">
            hm
          </td>
          <td id="prediction_td" title="일산화탄소" bgcolor="#2D3A80">
            co
          </td>
          <td id="prediction_td" title="이산화황" bgcolor="#2D3A80">
            so2
          </td>
        </tr>
        <tr id="prediction_tr" align="center" bgcolor="#B38F75">
          <td id="prediction_td" title="이슬점온도">
            td
          </td>
          <td id="prediction_td" title="바람 x좌표">
            Wind_x
          </td>
          <td id="prediction_td" title="오존" bgcolor="#2D3A80">
            o3
          </td>
          <td id="prediction_td" title="이산화질소" bgcolor="#2D3A80">
            no2
          </td>
        </tr>
        <tr id="prediction_tr" align="center" bgcolor="#B38F75">
          <td id="prediction_td" title="지면온도">
            ts
          </td>
          <td id="prediction_td" title="바람 y좌표">
            Wind_y
          </td>
          <td id="prediction_td" title="일사량" bgcolor="#2D3A80">
            icsr
          </td>
          <td id="prediction_td" title="교통량" bgcolor="#2D3A80">
            traffic
          </td>
        </tr>
        <tr id="prediction_tr" align="center" bgcolor="#B38F75">
          <td id="prediction_td" title="10cm 지중온도">
            m01Te
          </td>
          <td id="prediction_td" title="20cm 지중온도">
            m02Te
          </td>
          <td
            id="prediction_td"
            title="시정 (물체나 빛이 분명하게 보이는 최대거리(가시거리)의 측정 기준"
            bgcolor="#B8CFEA"
          >
            vs
          </td>
          <td id="prediction_td" title="증기압" bgcolor="#B8CFEA">
            pv
          </td>
        </tr>
        <tr id="prediction_tr" align="center" bgcolor="#B38F75">
          <td id="prediction_td" title="5cm 지중온도">
            m005Te
          </td>
          <td id="prediction_td" title="30cm 지중온도">
            m03Te
          </td>
          <td
            id="prediction_td"
            title="백령도 미세먼지(PM-10) 농도"
            bgcolor="#B8CFEA"
          >
            pm10_b
          </td>
          <td
            id="prediction_td"
            title="백령도 초미세먼지(PM-2.5) 농도"
            bgcolor="#B8CFEA"
          >
            pm25_b
          </td>
        </tr>
      </tbody>
    </table>
  );

  return (
    <div>
      <Box>
        <Container>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={3.5} alignItems="center" justify="center">
              <Grid
                item
                sx={{ marginBottom: 1, marginLeft: 1, marginRight: 1 }}
              >
                <Card>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Pretendard",
                      fontSize: 16,
                      fontWeight: 600,
                      textAlign: "left",
                      mt: 2,
                      ml: 3.5,
                      color: "#303030",
                    }}
                  >
                    실측 날짜
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer
                      sx={{
                        m: 1,
                        marginBottom: 1,
                        marginLeft: 1,
                        marginRight: 1,
                      }}
                      components={["DateTimePicker", "DateTimePicker"]}
                    >
                      <div>
                        <DateTimePicker
                          sx={{
                            m: 1,
                          }}
                          // maxDate={max_today} //오늘날짜 기준 이상 선택안됨
                          defaultValue={dayjs().add(-2, "hours")}
                          format="YYYY-MM-DD HH"
                          label="예측 기준 시간 선택"
                          // viewRenderers={{
                          //   hours: renderTimeViewClock,
                          // }}
                          views={["year", "month", "day", "hours"]}
                          onChange={(newValue) => dateRealValue(newValue)}
                          maxDateTime={dayjs().add(-2, "hours")}
                          disableFuture
                        />
                      </div>
                    </DemoContainer>
                  </LocalizationProvider>
                  <Box sx={{ mt: 1, mb: 2 }}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Pretendard",
                        fontSize: 16,
                        fontWeight: 600,
                        textAlign: "left",
                        ml: 3.5,
                        mb: 4,
                        color: "#303030",
                      }}
                    >
                      시간 선택
                    </Typography>
                    <Box sx={{ ml: 7, mr: 7 }}>
                      <Slider
                        aria-label="Always visible"
                        defaultValue={1}
                        getAriaValueText={valuetext}
                        step={1}
                        max={24}
                        min={1}
                        marks={marks}
                        valueLabelDisplay="on"
                        onChange={onSliderTimeChange}
                      />
                    </Box>
                  </Box>
                </Card>
              </Grid>
              <Grid item sx={{ m: 1 }}>
                <Card>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Pretendard",
                      fontSize: 16,
                      fontWeight: 600,
                      textAlign: "left",
                      mt: 2,
                      ml: 3.5,
                      mb: 1,
                      color: "#303030",
                    }}
                  >
                    동 이름 검색
                  </Typography>
                  <Autocomplete
                    disablePortal
                    sx={{ ml: 3, mr: 3.3, mb: 3 }}
                    id="combo-box-demo"
                    defaultValue={autocomplete_value}
                    options={DongNameList1}
                    value={autocomplete_value || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.dongName === value.dongName
                    }
                    onKeyDown={(e) => {
                      if (e.code === "enter" && e.target.value) {
                        setAutocomplete_value(
                          autocomplete_value.concat(e.target.value)
                        );
                      }
                    }}
                    onChange={(_, newValue) => {
                      if (newValue !== null) {
                        setAutocomplete_value(newValue);
                        onPredictionInfo(newValue);
                      }
                    }}
                    inputValue={autocomplete_value}
                    onInputChange={(_, newInputValue) => {
                      if (newInputValue !== null) {
                        setAutocomplete_value(newInputValue);
                        onPredictionInfo(newInputValue);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="동이름 검색" />
                    )}
                  />
                </Card>
              </Grid>
              <Grid item sx={{ m: 1 }}>
                <Card sx={{ width: "100%" }}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Pretendard",
                      fontSize: 16,
                      fontWeight: 600,
                      textAlign: "left",
                      mt: 2,
                      ml: 3.2,
                      mb: 1,
                      color: "#303030",
                    }}
                  >
                    추론 환경요인표
                  </Typography>
                  {imageTable}
                </Card>
              </Grid>
              <Grid item sx={{ m: 1 }}>
                <Card sx={{ height: 214 }}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Pretendard",
                      fontSize: 16,
                      fontWeight: 600,
                      textAlign: "left",
                      mt: 2,
                      ml: 3.2,
                      color: "#303030",
                    }}
                  >
                    환경요인률
                  </Typography>
                  <GraphPie data={senserdongImage}></GraphPie>
                </Card>
              </Grid>
              <Grid item sx={{ m: 1 }}>
                <Card sx={{ height: 200 }}>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Pretendard",
                      fontSize: 16,
                      fontWeight: 600,
                      textAlign: "left",
                      mt: 2,
                      ml: 3.2,
                      mb: 1,
                      color: "#303030",
                    }}
                  >
                    {senserdong} 추론 결과 이미지
                  </Typography>
                  {/* <CardMedia
                    sx={{ width: "86%", height: "130px", ml: 3, mr: 3, mb: 3 }}
                    component="img"
                    image={
                      senserdongImage === undefined
                        ? undefined
                        : `http://112.217.202.219:8080/${senserdongImage[0].imagePath}`
                    }
                  /> */}

                  <CardMedia
                    sx={{ width: "86%", height: "130px", ml: 3, mr: 3, mb: 3 }}
                    component="img"
                    image={
                      senserdongImage === undefined
                        ? undefined
                        : `http://ecobaron.azwellsys.com/${senserdongImage[0].imagePath}`
                    }
                  />
                </Card>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={8.5}
              alignItems="center"
              justifyContent="center"
            >
              <Item>
                <Grid container>
                  <Grid item sm={2} xs={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Pretendard",
                        fontSize: 18,
                        fontWeight: 600,
                        textAlign: "left",
                        ml: 1.5,
                        color: "#3B4056",
                      }}
                    >
                      예측 기준 날짜
                    </Typography>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Pretendard",
                        fontSize: 18,
                        fontWeight: 400,
                        textAlign: "left",
                        color: "#909090",
                      }}
                    >
                      {dateReal} {timeReal}시 {cvTextTime + 1}시간 후
                    </Typography>
                  </Grid>
                  <Grid item sm={1}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Pretendard",
                        fontSize: 18,
                        fontWeight: 400,
                        textAlign: "left",
                        color: "#E8E8E8",
                      }}
                    >
                      |
                    </Typography>
                  </Grid>
                  <Grid item sm={1} xs={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Pretendard",
                        fontSize: 18,
                        fontWeight: 600,
                        textAlign: "left",
                        color: "#3B4056",
                      }}
                    >
                      예측
                    </Typography>
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Pretendard",
                        fontSize: 18,
                        fontWeight: 400,
                        textAlign: "left",
                        color: "#909090",
                      }}
                    >
                      {cvTextDate.substr(0, 8)} {cvTextDate.substr(8, 2)} 시
                      데이터
                    </Typography>
                  </Grid>
                </Grid>
              </Item>
              <Card>
                <Grid container xs={12}>
                  <Card
                    className="m-auto"
                    sx={{ m: 1, minHeight: 600, maxHeight: 1010 }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        fontFamily: "Pretendard",
                        fontSize: 18,
                        fontWeight: 600,
                        textAlign: "left",
                        mt: 3,
                        ml: 3.5,
                        color: "#3B4056",
                      }}
                    >
                      지도 데이터
                    </Typography>
                    <Box sx={{ m: 2 }}>
                      <div id="container_test2">
                        {linerProgress ? <Linear></Linear> : null}
                        <div id="container_test">
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Box
                              sx={{
                                width: "100vw",
                                height: "100vh",
                                justifyContent: "center",
                                justifyItems: "center",
                              }}
                            >
                              <canvas
                                hidden
                                id="canvasID"
                                style={{ overflow: "hidden" }}
                              >
                                Canvas not supported
                              </canvas>
                              <Grid container direction="row">
                                <div
                                  ref={mapContainer}
                                  style={{
                                    width: "780px",
                                    height: "800px",
                                    position: "relative",
                                    borderRadius: "10px",
                                  }}
                                >
                                  <canvas id="cv">Canvas not supported</canvas>
                                </div>
                                <img
                                  id="markerTextImg"
                                  width="152px"
                                  height="43px"
                                  src={markerTextImg}
                                  alt="markerTextImg"
                                />
                              </Grid>
                            </Box>
                          </Grid>
                        </div>
                      </div>
                    </Box>
                  </Card>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
}

export default DustPrediction;
