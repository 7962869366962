import Router from "./Components/Router";
import "./App.css";
import Footer from "./Components/Footer";


function App() {
  return (
    <div className="App">
       <Router />

   
        <Footer />


    </div>
  );
}

export default App;
