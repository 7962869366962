import * as React from "react";
import secureLocalStorage from "react-secure-storage";

function Logout() {
  secureLocalStorage.removeItem("userID");
  secureLocalStorage.removeItem("idx");
  secureLocalStorage.removeItem("userAuthority");
}

export default Logout;
