import * as React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";


function GraphMixbar(param) {
  return (
    <ResponsiveContainer id="bar-chart-container" width={220} height={220}>
      <BarChart
        data={param.data}
        margin={{
          top: 5,
          right: 1,
          left: 1,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="Date" />
        <YAxis />
        <Tooltip />
        <Legend />
        <ReferenceLine y={0} stroke="#000" />
        <Bar dataKey="pm2.5" stackId="a">
          {param.data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Bar>
        <Bar dataKey="봄" stackId="a" fill="#4571E9" />
        <Bar dataKey="여름" stackId="a" fill="#CFDCFF" />
        <Bar dataKey="가을" stackId="a" fill="#45818e" />
        <Bar dataKey="겨울" stackId="a" fill="#B9A292" />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default GraphMixbar;
