const GET_DUST_MAIN = "dust/GET_DUST_MAIN";
const GET_DUST_MAIN_PARAM = "dust/GET_DUST_MAIN_PARAM";
const GET_DUST_LIST_PARAM = "dust/GET_DUST_LIST_PARAM";

export const getDustMain = () => ({ type: GET_DUST_MAIN });
export const getDustMainParam = (param) => ({
  type: GET_DUST_MAIN_PARAM,
  param,
});
export const getDustListParam = (param) => ({
  type: GET_DUST_LIST_PARAM,
  param,
});

const initialState = {
  dust: [
    {
      lonlat: [127.3961597522, 36.3379512241],
      dongName: "",
      dong: "",
      pm10Value: "",
      pm25Value: "",
    },
  ],
  dust_list:[]
};

export default function funGetDust(state = initialState, action) {
  switch (action.type) {
    case GET_DUST_MAIN:
      return {
        ...state,
        dust: state.dust,
      };
    case GET_DUST_MAIN_PARAM:
      return {
        ...state,
        dust: action.param,
      };

    case GET_DUST_LIST_PARAM:
      return {
        ...state,
        dust_list: action.param,
      };

    default:
      return state;
  }
}
