import * as React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
  Brush,
} from "recharts";

function GraphLine(param) {

  return (
    <ResponsiveContainer id="line-chart-container" width={270} height={240}>
      <LineChart
        data={param.data}
        margin={{
          top: 5,
          right: 1,
          left: 1,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="dongName" />
        <YAxis type="number" domain={[0, 150]} allowDataOverflow={true} />
        <Legend verticalAlign="top" height={36} />
        <ReferenceLine y={0} stroke="#000" />
        <Brush
          dataKey="dongName"
          height={20}
          stroke="#8884d8"
          travellerWidth={15}
        />
        <Line
          name="실측값"
          type="monotone"
          dataKey="pm10Value"
          stroke="#4571E9"
          activeDot={{ r: 8 }}
        />
        <Line
          name="예측값"
          type="monotone"
          dataKey="pre_pm10Value"
          stroke="#82ca9d"
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

export default GraphLine;
